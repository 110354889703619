import batteryClamp300AmpImage from "../../../static/images/tools/batteryTerminalHolder/batteryClamp300Amp.jpg";

const batteryClamp300Amp = {
    name: 'Battery Clamp (300 Amp)',
    image: batteryClamp300AmpImage,
    images: [batteryClamp300AmpImage],
    modelNos: ["111-7300"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [],
    detail: () => null,
    path: '/tools/batteryTerminalHolder/batteryClamp300Amp',
    id: 'batteryClamp300Amp'
};

export default batteryClamp300Amp;