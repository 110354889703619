import dualTireChuckWithRubberImage from "../../../static/images/tools/dualTireChuck/600-1122.jpg";
import dualTireChuckWithRubberImage1 from "../../../static/images/tools/dualTireChuck/600-1126.jpg";
import DualTireChuckWithRubber
    from "../../../components/ToolDetails/DualTireChuck/DualTireChuckWithRubber/DualTireChuckWithRubber";

const dualTireChuckWithRubber = {
    name: 'Dual Tire Chuck With Rubber',
    image: dualTireChuckWithRubberImage,
    images: [dualTireChuckWithRubberImage, dualTireChuckWithRubberImage1],
    modelNos: ["600-1126", "600-1137", "600-1122", "600-1138", "600-1139"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [],
    detail: DualTireChuckWithRubber,
    path: '/tools/dualTireChuck/dualTireChuckWithRubber',
    id: 'dualTireChuckWithRubber'
}

export default dualTireChuckWithRubber;