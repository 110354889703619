import diagonalCuttingPlierWithStripperImage from "../../../static/images/tools/wireCutters/cn130.jpg";
import diagonalCuttingPlierStripperMeasurement from "../../../static/images/tools/wireCutters/cn130Measurements.png";
import DiagonalCuttingPliersWithStripper
    from "../../../components/ToolDetails/WireCutters/DiagonalCuttingPliersWithStripper/DiagonalCuttingPliersWithStripper";

const diagonalCuttingPlierWithStripper = {
    name: 'Diagonal Cutting Pliers (w/ Stripper)',
    image: diagonalCuttingPlierWithStripperImage,
    images: [diagonalCuttingPlierWithStripperImage, diagonalCuttingPlierStripperMeasurement],
    modelNos: ["CN-130", "CN-160"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For copper and iron wires.",
        "With a stripping hole on the edge.",
        "Diagonal Cutter 4 types & 3 sizes",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C）",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 58～64",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: DiagonalCuttingPliersWithStripper,
    path: '/tools/wireCutters/diagonalcuttingplierswithstripper',
    id: 'diagonalcuttingplierswithstripper'
};

export default diagonalCuttingPlierWithStripper;