import telescopingMagneticPickUpToolImage from "../../../static/images/tools/otherAutomobileWorkshopTools/299-1094.jpg";
import TelescopingMagneticPickUpTool
    from "../../../components/ToolDetails/OtherAutomobileWorkshopTools/TelescopingMagneticPickUpTool/TelescopingMagneticPickUpTool";

const telescopingMagneticPickUpTool = {
    name: 'Telescoping Magnetic Pick-Up Tool',
    image: telescopingMagneticPickUpToolImage,
    images: [telescopingMagneticPickUpToolImage],
    modelNos: ["299-1094", "299-1095", "299-1096"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [],
    detail: TelescopingMagneticPickUpTool,
    path: '/tools/otherAutomobileWorkshopTools/telescopingMagneticPickUpTool',
    id: 'telescopingMagneticPickUpTool'
};

export default telescopingMagneticPickUpTool;