import wireRopeCutterwithLockImage from "../../../static/images/tools/wireCutters/wc-200.jpg";
import wireRopeCutterwithLockMeasurements from "../../../static/images/tools/wireCutters/wc-200.png";
import WireRopeCutterwithLock from "../../../components/ToolDetails/WireCutters/WireRopeCutterwithLock/WireRopeCutterwithLock";

const wireRopeCutterwithLock = {
    name: 'Wire Rope Cutter with Lock',
    image: wireRopeCutterwithLockImage,
    images: [wireRopeCutterwithLockImage, wireRopeCutterwithLockMeasurements],
    modelNos: ["WC-200", "WC-225"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
		"Cutting wire rope cleanly, without becoming loose.",
		"With one hand, cutting φ5mm(WC-200) or φ6mm(WC-225) of iron wire rope.",
    ],
    specifications: [
		"Material | Alloy steel",
		"Heat Treatment | Induction hardening on edge",
		"Edge hardness [HRC] | 54～64",
		"Spring | Volute spring",
		"Handle cover | PVC dipping",
    ],
    detail: WireRopeCutterwithLock,
    path: '/tools/wireCutters/wireRopeCutterWithLock',
    id: 'wireRopeCutterWithLock'
};

export default wireRopeCutterwithLock;