import endCuttingPliersImage from "../../../static/images/tools/miniTools/en115.jpg";
import endCuttingPliersMeasurements from "../../../static/images/tools/miniTools/en115.png";
import EndCuttingPliers from "../../../components/ToolDetails/MiniTools/EndCuttingPliers/EndCuttingPliers";

const endCuttingPliers = {
    name: 'End Cutting Pliers',
    image: endCuttingPliersImage,
    images: [endCuttingPliersImage, endCuttingPliersMeasurements],
    modelNos: ["EN-115"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
		"For cutting protrusions on the surface.",
		"Suitable for building miniature models and various handicrafts. ",
    ],
    specifications: [
		"Material | Carbon steel for machinery",
		"Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
		"Body hardness [HRC] | 42～50",
		"Edge hardness [HRC] | 54～62",
		"Spring | Coil spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: EndCuttingPliers,
    path: '/tools/miniTools/endCuttingPliers',
    id: 'endCuttingPliers'
};

export default endCuttingPliers;