import duckBillPlierImage from "../../../static/images/tools/pliers/dbp200.jpg";
import duckBillPlierMeasurements from "../../../static/images/tools/pliers/dbp200Measurements.png";
import DuckBillPlier from "../../../components/ToolDetails/Pliers/DuckBillPlier/DuckBillPlier";

const duckBillPliers = {
    name: 'Duck Bill Pliers',
    image: duckBillPlierImage,
    images: [duckBillPlierImage, duckBillPlierMeasurements],
    modelNos: ["DBP-200"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Suitable for holding sheet metal.",
        "Wide tips of the jaw provide powerful holding.",
        "Serrated jaw inside.",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C）",
        "Heat Treatment | Oxide-free heat treatment",
        "Body hardness [HRC] | 42～50",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: DuckBillPlier,
    path: '/tools/pliers/duckBillPliers',
    id: 'duckBillPliers'
};
export default duckBillPliers;