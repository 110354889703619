import React from "react";
import {Carousel, Col, Container, Row} from "react-bootstrap";
import {categoriesToTools} from "../../constants/tools";
import {Next, Prev} from "../Icons/Icons";
import NotFound from "../NotFound/NotFound";

function specifications(tool) {
    return <>
        <h2>{tool.name}</h2>
        <Row>
            <Col lg={6} md={12}>
                Manufacturing Company: {tool.company}
            </Col>
            <Col lg={6} md={12}>
                Made In {tool.madeIn}
            </Col>
        </Row>
        {
            tool.description.length ? <div>
                <h4>Description</h4>
                <ul>
                    {tool.description.map((d, index) => <li key={index}>{d}</li>)}
                </ul>
            </div> : null
        }
        {
            tool.specifications.length ? <div>
                <h4>Specifications</h4>
                <ul>
                    {tool.specifications.map((s, index) => <li key={index}>{s}</li>)}
                </ul>
            </div> : null
        }
    </>;
}

function imageCarousel(images) {
    return <Carousel nextIcon={<Next/>} prevIcon={<Prev/>} interval={null} controls={images.length > 1}>
        {
            images.map((i, index) => <Carousel.Item key={index}>
                <img
                    className="s-block w-100"
                    src={i}
                    alt={""}
                />
            </Carousel.Item>)
        }
    </Carousel>;
}

export default function Tool({match}) {
    const categoryName = match.params.category;
    const toolName = match.params.tool.toLowerCase();
    const category = categoriesToTools[categoryName];
    if (!category) {
        return <NotFound/>
    }
    const tool = category.find(t => t.id.toLowerCase() === toolName)
    if (!tool) {
        return <NotFound/>
    }
    const Detail = tool.detail
    return <Container fluid>
        <Row>
            <Col md={5}>{imageCarousel(tool.images)} </Col>
            <Col md={7}>{specifications(tool)}</Col>
        </Row>
        <div className='tool__details'>
            <Detail/>
        </div>
    </Container>
}