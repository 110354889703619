import React from "react";
import {Table} from "react-bootstrap";

export default function SlimPlasticCutterFlatBlade() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SNP-145F</td>
                <td>153</td>
                <td>13</td>
                <td>8</td>
                <td>48</td>
                <td>16</td>
                <td>100</td>
                <td>107067</td>
            </tr>
            <tr>
                <td>SNP-165F</td>
                <td>168</td>
                <td>16</td>
                <td>9</td>
                <td>48</td>
                <td>22</td>
                <td>130</td>
                <td>107074</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Plastic</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SNP-145F</td>
                <td>4.0</td>
            </tr>
            <tr>
                <td>SNP-165F</td>
                <td>5.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}