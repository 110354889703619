import React from "react";
import {Table} from "react-bootstrap";

export default function MicroLongNosePliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="7">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Edge length</th>
                <th>Hole diameter</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>ER-125</td>
                <td>140</td>
                <td>12.5</td>
                <td>8</td>
                <td>51</td>
                <td>43</td>
                <td>8</td>
                <td>1.2</td>
                <td>70</td>
                <td>103045</td>
            </tr>
            <tr>
                <td>ER-150</td>
                <td>158</td>
                <td>12.5</td>
                <td>8</td>
                <td>51</td>
                <td>51</td>
                <td>8</td>
                <td>1.2</td>
                <td>75</td>
                <td>103052</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Brass</th>
                <th>Stranded copper wire [mm²]</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>ER-125</td>
                <td>1.2</td>
                <td>1.6</td>
                <td>2.0</td>
                <td>1.6</td>
                <td>2.0</td>
            </tr>
            <tr>
                <td>ER-150</td>
                <td>1.6</td>
                <td>1.6</td>
                <td>2.6</td>
                <td>2.0</td>
                <td>2.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}
