import plasticCuttingPliersImage from "../../../static/images/tools/wireCutters/pn125.jpg";
import plasticCuttingPliersMeasurement from "../../../static/images/tools/wireCutters/pn125Measurements.png";
import PlasticCuttingPliers
    from "../../../components/ToolDetails/WireCutters/PlasticCuttingPliers/PlasticCuttingPliers";

const plasticCuttingPliers = {
    name: 'Plastic Cutting Pliers',
    image: plasticCuttingPliersImage,
    images: [plasticCuttingPliersImage, plasticCuttingPliersMeasurement],
    modelNos: ["PN-125", "PN-150", "PN-175"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Light weight and sharp edge reduce fatigue of continuous cutting work.",
        "Flat blade is suitable for cutting targets on the curved surface.",
    ],
    specifications: [
        "Material | Alloy steel",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Edge hardness [HRC] | 58～64",
        "Spring | Coil spring",
        "Handle cover | PVC dipping",
    ],
    detail: PlasticCuttingPliers,
    path: '/tools/wireCutters/plasticCuttingPliers',
    id: 'plasticCuttingPliers'
};

export default plasticCuttingPliers;