import snapRingPliersForSmallInternalRingsImage from "../../../static/images/tools/pliers/msi125.jpg";
import snapRingPliersForSmallInternalRingsMeasurements
    from "../../../static/images/tools/pliers/msi125Measurements.png";
import SnapRingPliersForSmallInternalRings
    from "../../../components/ToolDetails/Pliers/SnapRingPliersForSmallInternalRings/SnapRingPliersForSmallInternalRings";

const snapRingPliersForSmallInternalRings = {
    name: 'Snap Ring Pliers for Small Internal Rings',
    image: snapRingPliersForSmallInternalRingsImage,
    images: [snapRingPliersForSmallInternalRingsImage, snapRingPliersForSmallInternalRingsMeasurements],
    modelNos: ["MSI-125"],
    company: "King TTC",
    madeIn: 'Japan',
    description: ["For the smallest internal snap rings."],
    specifications: [
        "Material | Carbon steel for machinery",
        "Heat Treatment | Oxide-free heat treatment",
        "Hardness | 42～46",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: SnapRingPliersForSmallInternalRings,
    path: '/tools/pliers/snapRingPliersForSmallInternalRings',
    id: 'snapRingPliersForSmallInternalRings'
};
export default snapRingPliersForSmallInternalRings;