import greaseNipplesInchSize95pcsImage from "../../../static/images/tools/greaseNipples/GE-843.jpg";
import GreaseNipplesInchSize95pcs
    from "../../../components/ToolDetails/GreaseNipples/GreaseNipplesMetricSize95pcs/GreaseNipplesInchSize95pcs";

const greaseNipplesInchSize95pcs = {
    name: '95pcs Grease Nipples (Inch Size)',
    image: greaseNipplesInchSize95pcsImage,
    images: [greaseNipplesInchSize95pcsImage],
    modelNos: ["GE-843"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: ["Kit contains 6 popular & useful inch sizes."],
    specifications: [],
    detail: GreaseNipplesInchSize95pcs,
    path: '/tools/greaseNipples/greaseNipplesInchSize95pcs',
    id: 'greaseNipplesInchSize95pcs'
}

export default greaseNipplesInchSize95pcs;