import React from "react";
import {Table} from "react-bootstrap";

export default function SnapRingPliersBentJawExternalRings() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="6">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">JAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Handle width</th>
                <th>Bent part length</th>
                <th>Nose length</th>
                <th>Nose angle</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SEB-125P</td>
                <td>136</td>
                <td>18</td>
                <td>71</td>
                <td>18</td>
                <td>18</td>
                <td>95°</td>
                <td>110</td>
                <td>110098</td>
            </tr>
            <tr>
                <td>SEB-175P</td>
                <td>166</td>
                <td>22</td>
                <td>85</td>
                <td>20</td>
                <td>23</td>
                <td>95°</td>
                <td>170</td>
                <td>110135</td>
            </tr>
            <tr>
                <td>SEB-230P</td>
                <td>230</td>
                <td>24</td>
                <td>111</td>
                <td>26</td>
                <td>26</td>
                <td>95°</td>
                <td>285</td>
                <td>110173</td>
            </tr>
            <tr>
                <td>SEB-300P</td>
                <td>305</td>
                <td>29</td>
                <td>145</td>
                <td>32</td>
                <td>33</td>
                <td>95°</td>
                <td>535</td>
                <td>113532</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th/>
                <th>Sizes across flats : (φmm)</th>
                <th>Usable range（mm)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SEB-125P</td>
                <td>1.4</td>
                <td>12～25</td>
            </tr>
            <tr>
                <td>SEB-175P</td>
                <td>1.8</td>
                <td>19～40</td>
            </tr>
            <tr>
                <td>SEB-230P</td>
                <td>2.3</td>
                <td>32～75</td>
            </tr>
            <tr>
                <td>SEB-300P</td>
                <td>3.0</td>
                <td>75～140</td>
            </tr>
            </tbody>
        </Table>
    </>
}