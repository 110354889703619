import flatScrewDrivers from "./screwdriversAndTesters/flatScrewDrivers";
import phillipsScrewDriver from "./screwdriversAndTesters/phillipsScrewDriver";
import lineTesters from "./screwdriversAndTesters/lineTesters";

const screwdriversAndTesters = [
    flatScrewDrivers,
    phillipsScrewDriver,
    lineTesters,
]

export default screwdriversAndTesters;