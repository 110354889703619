import powerMetalCutterImage from "../../../static/images/tools/miniTools/pc1100.jpg";
import powerMetalCutterMeasurements from "../../../static/images/tools/miniTools/pc1100.png";
import PowerMetalCutter from "../../../components/ToolDetails/MiniTools/PowerMetalCutter/PowerMetalCutter";

const powerMetalCutter = {
    name: 'POWER Metal Cutter ',
    image: powerMetalCutterImage,
    images: [powerMetalCutterImage, powerMetalCutterMeasurements],
    modelNos: ["PC-1100"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
		"For cutting hard material like piano wire with ease.",
		"Hardened, long life handles.",
    ],
    specifications: [
		"Material | Alloy steel",
		"Blade hardness [HRC] | 61～64",
    ],
    detail: PowerMetalCutter,
    path: '/tools/miniTools/powerMetalCutter',
    id: 'powerMetalCutter'
};

export default powerMetalCutter;