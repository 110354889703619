import React from "react";
import {Table} from "react-bootstrap";

export default function PlasticCuttingPliersWithJawOpeningLimiter() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <td rowSpan="2"/>
                <td colSpan="5">Size[mm]</td>
                <td rowSpan="2">Weight<br/>[g]</td>
                <td rowSpan="2">EAN-code<br/>(4952269)</td>
            </tr>
            <tr>
                <td>Overall length</td>
                <td>Head width</td>
                <td>Head thickness</td>
                <td>Handle width</td>
                <td>Edge length</td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>KT-411</td>
                <td>127</td>
                <td>15</td>
                <td>8</td>
                <td>52</td>
                <td>16</td>
                <td>80</td>
                <td>110470</td>
            </tr>
            <tr>
                <td>KT-412</td>
                <td>151</td>
                <td>16</td>
                <td>10</td>
                <td>52</td>
                <td>21</td>
                <td>120</td>
                <td>110487</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Plastic</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>KT-411</td>
                <td>5.0</td>
            </tr>
            <tr>
                <td>KT-412</td>
                <td>5.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}