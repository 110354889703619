import diagonalCuttingPlierImage from "../../../static/images/tools/wireCutters/cn175n.jpg";
import diagonalCuttingPlierMeasurementImage from "../../../static/images/tools/wireCutters/cn175nMeasurements.jpg";
import DiagonalCuttingPliers from "../../../components/ToolDetails/WireCutters/DiagonalCuttingPliers/DiagonalCuttingPliers";

const diagonalCuttingPlier = {
    name: 'Diagonal Cutting Pliers',
    image: diagonalCuttingPlierImage,
    images: [diagonalCuttingPlierImage, diagonalCuttingPlierMeasurementImage],
    modelNos: ["CN-175N"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For copper and iron wires.",
        "w/o Stripping hole version."
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C)",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 58～64",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: DiagonalCuttingPliers,
    path: '/tools/wireCutters/diagonalcuttingpliers',
    id: 'diagonalCuttingPliers'
};

export default diagonalCuttingPlier;