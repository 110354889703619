import React from "react";
import {Table} from "react-bootstrap";

export default function QuickReleaseReversibleRatchetHandleKnurledGrip() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>72 Teeth<br/> Model No</th>
                <th>Drive</th>
                <th>Length<br/> (mm)</th>
                <th>Height<br/> (mm)</th>
                <th>Width<br/> (mm)</th>
                <th>Weight<br/> (g)</th>
                <th>Carton<br/> (pcs)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>361-1002</td>
                <td><sup>1</sup>&frasl;<sub>4</sub><sup>&Prime;</sup></td>
                <td>130</td>
                <td>25</td>
                <td>26</td>
                <td>142</td>
                <td>100</td>
            </tr>
            <tr>
                <td>361-1003</td>
                <td><sup>3</sup>&frasl;<sub>8</sub><sup>&Prime;</sup></td>
                <td>181</td>
                <td>33</td>
                <td>32</td>
                <td>336</td>
                <td>48</td>
            </tr>
            <tr>
                <td>361-1004</td>
                <td><sup>1</sup>&frasl;<sub>2</sub><sup>&Prime;</sup></td>
                <td>257</td>
                <td>42</td>
                <td>40</td>
                <td>658</td>
                <td>24</td>
            </tr>
            </tbody>
        </Table>
    </>
}
