import tinCutterWithSpringImage from "../../../static/images/tools/wireCutters/tinCutterWithSpring.jpg";
import tinCutterWithSpringMeasurements
    from "../../../static/images/tools/wireCutters/tinCutterWithSpringMeasrements.jpg";
import TinCutterWithSpring from "../../../components/ToolDetails/WireCutters/TinCutterWithSpring/TinCutterWithSpring";

const tinCutterWithSpring = {
    name: 'Tin Cutter With Spring',
    image: tinCutterWithSpringImage,
    images: [tinCutterWithSpringImage, tinCutterWithSpringMeasurements],
    modelNos: ["TCS 08", "TCS 10", "TCS 12", "TCS 14"],
    company: "Taparia",
    madeIn: 'India',
    description: [
        "Generally Conforming to IS 6087-1971",
        "Drop forged from high quality carbon steel. Scientifically heat treated to give tough body. The cutting edges are induction hardened. The cutting edges are sharp and precision machined to appropriate angle to cut sheets with ease. Provided with fixed stopper,which protects fingers from getting injured due to excessive closing on shanks."
    ],
    specifications: [],
    detail: TinCutterWithSpring,
    path: '/tools/wireCutters/tinCutterWithSpring',
    id: 'tinCutterWithSpring'
};

export default tinCutterWithSpring;