import React from "react";
import {Table} from "react-bootstrap";

export default function DiagonalCuttingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CN-175N</td>
                <td>189</td>
                <td>22</td>
                <td>12</td>
                <td>52</td>
                <td>24</td>
                <td>230</td>
                <td>102048</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Stranded copper wire [mm²]</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CN-175N</td>
                <td>2.0</td>
                <td>2.6</td>
                <td>3.6</td>
                <td>5.5</td>
            </tr>
            </tbody>
        </Table>
    </>
}