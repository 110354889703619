import React from "react";
import {Table} from "react-bootstrap";

export default function SnapRingPliersForSmallExternalRings() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="7">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Tip length</th>
                <th>Tip diameter</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>MSE-125</td>
                <td>128</td>
                <td>12</td>
                <td>9</td>
                <td>61</td>
                <td>23</td>
                <td>3</td>
                <td>0.65</td>
                <td>45</td>
                <td>110371</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th/>
                <th>Tip diameter(mm)</th>
                <th>Usable range(mm)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>MSE-125</td>
                <td>0.65</td>
                <td>3～9</td>
            </tr>
            </tbody>
        </Table>
    </>
}