import greaseNipplesMetricSize150pcsImage from "../../../static/images/tools/greaseNipples/GE-216.jpg";
import GreaseNipplesInchSize150pcs
    from "../../../components/ToolDetails/GreaseNipples/GreaseNipplesInchSize150pcs/GreaseNipplesInchSize150pcs";

const greaseNipplesMetricSize150pcs = {
    name: '150pcs Grease Nipples (Metric Size)',
    image: greaseNipplesMetricSize150pcsImage,
    images: [greaseNipplesMetricSize150pcsImage],
    modelNos: ["GE-216"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: ["Kit contains 8 popular & useful metric sizes."],
    specifications: [],
    detail: GreaseNipplesInchSize150pcs,
    path: '/tools/greaseNipples/greaseNipplesMetricSize150pcs',
    id: 'greaseNipplesMetricSize150pcs'
}

export default greaseNipplesMetricSize150pcs;