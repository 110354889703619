import React from "react";
import {Table} from "react-bootstrap";

export default function PlasticCuttingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>PN-100</td>
                <td>102</td>
                <td>11</td>
                <td>7</td>
                <td>44</td>
                <td>
                </td>
                <td>50</td>
                <td>114355</td>
            </tr>
            <tr>
                <td>PN-115</td>
                <td>117</td>
                <td>14</td>
                <td>7.5</td>
                <td>51</td>
                <td>15</td>
                <td>60</td>
                <td>407044</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Plastic</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>PN-100</td>
                <td>4.0</td>
            </tr>
            <tr>
                <td>PN-115</td>
                <td>5.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}
