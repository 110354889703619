import React from "react";
import {Table} from "react-bootstrap";

export default function MicroCuttingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
            </tr>

            </thead>
            <tbody>
            <tr>
                <td>MN-125</td>
                <td>126</td>
                <td>13</td>
                <td>8</td>
                <td>48</td>
                <td>13</td>
                <td>70</td>
                <td>402124</td>
            </tr>

            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Brass</th>
                <th>Stranded copper wire [mm²]</th>
            </tr>

            </thead>
            <tbody>
            <tr>
                <td>MN-125</td>
                <td>0.9</td>
                <td>1.6</td>
                <td>2.0</td>
                <td>1.6</td>
                <td>3.5mm²</td>
            </tr>

            </tbody>
        </Table>
    </>
}
