import React from "react";
import {Table} from "react-bootstrap";

export default function GroovedWaterPumpPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="4">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Nose length</th>
                <th>Head thickness</th>
                <th>Handle width</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>WP-250</td>
                <td>254</td>
                <td>49</td>
                <td>10</td>
                <td>46</td>
                <td>330</td>
                <td>106084</td>
            </tr>
            <tr>
                <td>WP-300</td>
                <td>304</td>
                <td>54</td>
                <td>11</td>
                <td>50</td>
                <td>500</td>
                <td>106091</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Usable range</th>
                <th>Maximum opening width (mm)</th>
                <th>Pipe External Diameter (mm)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>WP-250</td>
                <td>38</td>
                <td>6～46</td>
            </tr>
            <tr>
                <td>WP-300</td>
                <td>49</td>
                <td>6～58</td>
            </tr>
            </tbody>
        </Table>
    </>
}