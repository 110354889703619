import cableCutterImage from "../../../static/images/tools/wireCutters/ca650.jpg";
import CableCutter from "../../../components/ToolDetails/WireCutters/CableCutter/CableCutter";

const cableCutter = {
    name: 'Cable Cutter',
    image: cableCutterImage,
    images: [cableCutterImage],
    modelNos: ["CA-450", "CA-650", "CA-950"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For cutting copper core cable.",
        "Clean cut surface.",
    ],
    specifications: [
        "Surface treatment (Head) | Phosphate conversion coating"
    ],
    detail: CableCutter,
    path: '/tools/wireCutters/cableCutter',
    id: 'cableCutter'
};

export default cableCutter;