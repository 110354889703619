import aviationTinCutterWithSpringImage from "../../../static/images/tools/wireCutters/aviationTinCutterWithSpring.jpg";
import AviationTinCutterWithSpring
    from "../../../components/ToolDetails/WireCutters/AviationTinCutterWithSpring/AviationTinCutterWithSpring";

const aviationTinCutterWithSpring = {
    name: 'Aviation Tin Cutter with Spring',
    image: aviationTinCutterWithSpringImage,
    images: [aviationTinCutterWithSpringImage],
    modelNos: ["ATS10"],
    company: "Taparia",
    madeIn: 'India',
    description: [
        "Generally Conforming to IS 6087-1971",
        "Drop forged from high quality carbon steel. Scientifically heat treated to give tough body. The cutting edges are induction hardened. The cutting edges are sharp and precision machined to appropriate angle to cut sheets with ease. Provided with fixed stopper,which protects fingers from getting injured due to excessive closing on shanks."
    ],
    specifications: [],
    detail: AviationTinCutterWithSpring,
    path: '/tools/wireCutters/aviationTinCutterWithSpring',
    id: 'aviationTinCutterWithSpring'
};

export default aviationTinCutterWithSpring;