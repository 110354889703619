import pipeTapsBritishStandardImage from "../../../static/images/tools/skcTaps/pipeTapsBS.jpg";
import pipeTapsBritishStandardBSPS from "../../../static/images/tools/skcTaps/BSPS.jpg";
import pipeTapsBritishStandardBSPT from "../../../static/images/tools/skcTaps/BSPT.jpg";
import PipeTapsBritishStandards
    from "../../../components/ToolDetails/SkcTaps/PipeTapsBritishStandards/PipeTapsBritishStandards";

const pipeTapsBritishStandard = {
    name: 'Pipe Taps (British Standard)',
    image: pipeTapsBritishStandardImage,
    images: [pipeTapsBritishStandardImage, pipeTapsBritishStandardBSPS, pipeTapsBritishStandardBSPT],
    modelNos: ["804BT", "805BS"],
    company: "SKC Taps",
    madeIn: 'Japan',
    description: [
        "BSPT: British Standard Pipe Thread",
        "BSPS: British Standard Parallel Thread",
    ],
    specifications: [],
    detail: PipeTapsBritishStandards,
    path: '/tools/skcTaps/pipeTapsBritishStandard',
    id: 'pipeTapsBritishStandard'
};
export default pipeTapsBritishStandard;