import combinationPlierImage from "../../../static/images/tools/pliers/combinationPlier.jpg";
import combinationPlierMeasurements from "../../../static/images/tools/pliers/combinationPlierMeasurements.jpg";
import CombinationPlier from "../../../components/ToolDetails/Pliers/CombinationPlier/CombinationPlier";

const combinationPlier = {
    name: 'Combination Pliers',
    image: combinationPlierImage,
    images: [combinationPlierImage, combinationPlierMeasurements],
    modelNos: ["1621-6", "1621-7", "1621-8", "1611-6", "1611-7", "1611-8", "1601-6", "1601-7", "1601-8", "MCP-10", "MCP-12"],
    company: "Taparia",
    madeIn: 'India',
    description: [
        "Generally Conforming to IS 6149-1984 Grade II",
        "The Pliers made from high grade Steel, forged and differentially heat treated to give best performance. Fully insulated with quality plastic material sleeves ensures safe electrical working.",
        "It has excellent wire cutting ability for soft and hard wires. Available with fully polished head or Black phosphated and bright polished head.",
    ],
    specifications: [],
    detail: CombinationPlier,
    path: '/tools/pliers/combinationPlier',
    id: 'combinationPlier'
};
export default combinationPlier;