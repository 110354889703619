import React from "react";
import {Table} from "react-bootstrap";

export default function LongNosePliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="6">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>MR-100</td>
                <td>102</td>
                <td>10</td>
                <td>7</td>
                <td>44</td>
                <td>23</td>
                <td>
                </td>
                <td>40</td>
                <td>110395</td>
            </tr>
            <tr>
                <td>MR-115</td>
                <td>124</td>
                <td>12</td>
                <td>8</td>
                <td>51</td>
                <td>29</td>
                <td>6</td>
                <td>65</td>
                <td>403015</td>
            </tr>
            <tr>
                <td>MR-120</td>
                <td>132</td>
                <td>14</td>
                <td>8</td>
                <td>51</td>
                <td>37</td>
                <td>8</td>
                <td>70</td>
                <td>403138</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Brass</th>
                <th>Stranded copper wire [mm²]</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>MR-100</td>
                <td>-</td>
                <td>1.2</td>
                <td>1.6</td>
                <td>-</td>
                <td>2.0</td>
            </tr>
            <tr>
                <td>MR-115</td>
                <td>1.2</td>
                <td>1.6</td>
                <td>2.0</td>
                <td>1.6</td>
                <td>3.5</td>
            </tr>
            <tr>
                <td>MR-120</td>
                <td>1.2</td>
                <td>1.6</td>
                <td>2.0</td>
                <td>2.0</td>
                <td>3.5</td>
            </tr>
            </tbody>
        </Table>
    </>
}
