import groovedWaterPumpPliersImage from "../../../static/images/tools/pliers/wp250.jpg";
import groovedWaterPumpPliersMeasurements from "../../../static/images/tools/pliers/wp250Measurements.png";
import GroovedWaterPumpPliers from "../../../components/ToolDetails/Pliers/GroovedWaterPumpPliers/GroovedWaterPumpPliers";

const groovedWaterPumpPliers = {
    name: 'Grooved Water Pump Pliers',
    image: groovedWaterPumpPliersImage,
    images: [groovedWaterPumpPliersImage, groovedWaterPumpPliersMeasurements],
    modelNos: ["WP-250", "WP-300"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Groove joint type provides strong torque without slipping.",
        "5-step adjustment positions.",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S55C)",
        "Heat Treatment | Oxide-free heat treatment",
        "Body hardness [HRC] | 44～52",
        "Handle cover | PVC dipping",
        "Surface treatment | Nickel-chrome plating",
    ],
    detail: GroovedWaterPumpPliers,
    path: '/tools/pliers/groovedWaterPumpPliers',
    id: 'groovedWaterPumpPliers'
};
export default groovedWaterPumpPliers;