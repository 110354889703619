import standardHandTapsMetricStandardImage from "../../../static/images/tools/skcTaps/801.jpg";
import standardHandTapsMetricStandardMeasurements from "../../../static/images/tools/skcTaps/801Measurements.jpg";
import StandardHandTapsMetricStandard from "../../../components/ToolDetails/SkcTaps/StandardHandTapsMetricStandard/StandardHandTapsMetricStandard";

const standardHandTapsMetricStandard = {
    name: 'Standard Hand Taps',
    image: standardHandTapsMetricStandardImage,
    images: [standardHandTapsMetricStandardImage, standardHandTapsMetricStandardMeasurements],
    modelNos: ["801"],
    company: "SKC Taps",
    madeIn: 'Japan',
    description: [
        "3 pc one set",
    ],
    specifications: [
        "METRIC: 1.0mm upto 12mm in plastic case and 14mm and above in printed box.",
        "INCH: 1⁄16 inch upto 1⁄2 inch in plastic case and 9⁄16 inch and above in printed box.",
    ],
    detail: StandardHandTapsMetricStandard,
    path: '/tools/skcTaps/standardHandTapsMetricStandard',
    id: 'standardHandTapsMetricStandard'
};
export default standardHandTapsMetricStandard;