import microCuttingPliersImage from "../../../static/images/tools/miniTools/mn125.jpg";
import microCuttingPliersMeasurements from "../../../static/images/tools/miniTools/mn125.png";
import MicroCuttingPliers from "../../../components/ToolDetails/MiniTools/MicroCuttingPliers/MicroCuttingPliers";

const microCuttingPliers = {
    name: 'Micro Cutting Pliers',
    image: microCuttingPliersImage,
    images: [microCuttingPliersImage, microCuttingPliersMeasurements],
    description: [
		"Cutting stranded wires clearly with ease and clean face.",
    ],
    modelNos: ["MN-125"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
		"Material | Carbon steel for machinery",
		"Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
		"Body hardness [HRC] | 42～50",
		"Edge hardness [HRC] | 54～62",
		"Spring | Coil spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: MicroCuttingPliers,
    path: '/tools/miniTools/microCuttingPliers',
    id: 'microCuttingPliers'
};

export default microCuttingPliers;