import greaseNipplesMetricSize80pcs from "./greaseNipples/greaseNipplesMetricSize80pcs";
import greaseNipplesInchSize95pcs from "./greaseNipples/greaseNipplesInchSize95pcs";
import greaseNipplesInchSize110pcs from "./greaseNipples/greaseNipplesInchSize110pcs";
import greaseNipplesInchSize140pcs from "./greaseNipples/greaseNipplesInchSize140pcs";
import greaseNipplesMetricSize150pcs from "./greaseNipples/greaseNipplesMetricSize150pcs";

const greaseNipples = [
    greaseNipplesMetricSize80pcs,
    greaseNipplesInchSize95pcs,
    greaseNipplesInchSize110pcs,
    greaseNipplesInchSize140pcs,
    greaseNipplesMetricSize150pcs
]

export default greaseNipples;
