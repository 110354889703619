import React from "react";
import {Table} from "react-bootstrap";

export default function TinCutterWithSpring() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2">PRODUCT NO.</th>
                <th colSpan="4">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
            </tr>
            <tr>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>TCS 08</td>
                <td>200</td>
                <td>50</td>
                <td>28</td>
                <td>12</td>
                <td>250</td>
            </tr>
            <tr>
                <td>TCS 10</td>
                <td>250</td>
                <td>60</td>
                <td>32</td>
                <td>14</td>
                <td>450</td>
            </tr>
            <tr>
                <td>TCS 12</td>
                <td>300</td>
                <td>70</td>
                <td>36</td>
                <td>16</td>
                <td>650</td>
            </tr>
            <tr>
                <td>TCS 14</td>
                <td>350</td>
                <td>85</td>
                <td>42</td>
                <td>18</td>
                <td>1000</td>
            </tr>
            </tbody>
        </Table>
    </>
}