import plierImage from '../static/images/tools/pliers/pl150.jpg'
import wireCutterImage from '../static/images/tools/wireCutters/cn175n.jpg'
import miniToolsImage from '../static/images/tools/miniTools/en115.jpg'
import skcTapsImage  from '../static/images/tools/skcTaps/skc.jpg'
import socketImage from '../static/images/tools/socketAccessories/501-0418.jpg'
import screwdriverImage from '../static/images/tools/screwdriversAndTesters/flatsScrewdriver.jpg'
import batterTerminalHolderImage from '../static/images/tools/batteryTerminalHolder/batteryClamp300Amp.jpg'
import airImpactWrenchImage from '../static/images/tools/airImpactWrench/663-5080.jpg'
import valveSpringCompressorImage from '../static/images/tools/valveSpringCompressor/123-1201.jpg'
import oilFilterWrenchImage from '../static/images/tools/oilFilterWrench/215-1082.jpg'
import dualTireChuckImage from '../static/images/tools/dualTireChuck/600-1122.jpg'
import batterTestersImage from '../static/images/tools/batteryTesters/113-1957.jpg'
import greaseNipplesImage from '../static/images/tools/greaseNipples/GE-853.jpg'
import otherAutomobileWorkshopToolsImage from '../static/images/tools/otherAutomobileWorkshopTools/main.jpg'

import pliers from './tools/pliers';
import wireCutters from './tools/wireCutters';
import miniTools from "./tools/miniTools";
import skcTaps from "./tools/skcTaps";
import socketAccessories from "./tools/socketAccessories";
import screwdriversAndTesters from "./tools/screwdriversAndTesters";
import batteryTerminalHolder from "./tools/batteryTerminalHolder";
import airImpactWrench from "./tools/airImpactWrench";
import valveSpringCompressor from "./tools/valveSpringCompressor";
import oilFilterWrench from "./tools/oilFilterWrench";
import dualTireChuck from "./tools/dualTireChuck";
import otherAutomobileWorkshopTools from "./tools/otherAutomobileWorkshopTools";
import batteryTesters from "./tools/batteryTesters";
import greaseNipples from "./tools/greaseNipples";

const categories = [
    {
        name: 'Pliers',
        path: '/tools/pliers',
        image: plierImage
    },
    {
        name: 'Wire Cutters',
        path: '/tools/wireCutters',
        image: wireCutterImage
    },
    {
        name: 'Mini Tools',
        path: '/tools/miniTools',
        image: miniToolsImage
    },
    {
        name: 'SKC Taps',
        path: '/tools/skcTaps',
        image: skcTapsImage
    },
    {
        name: 'Socket Accessories',
        path: '/tools/socketAccessories',
        image: socketImage
    },
    {
        name: 'Screwdrivers and Testers',
        path: '/tools/screwdriversAndTesters',
        image: screwdriverImage
    },
    {
        name: 'Batter Terminal Holder',
        path: '/tools/batteryTerminalHolder',
        image: batterTerminalHolderImage
    },
    {
        name: 'Batter Terminal Holder',
        path: '/tools/airImpactWrench',
        image: airImpactWrenchImage
    },
    {
        name: 'Valve Spring Compressor',
        path: '/tools/valveSpringCompressor',
        image: valveSpringCompressorImage
    },
    {
        name: 'Oil Filter Wrench',
        path: '/tools/oilFilterWrench',
        image: oilFilterWrenchImage
    },
    {
        name: 'Dual Tire Chuck',
        path: '/tools/dualTireChuck',
        image: dualTireChuckImage
    },
    {
        name: 'Battery Testers',
        path: '/tools/batteryTesters',
        image: batterTestersImage
    },
    {
        name: 'Grease Nipples',
        path: '/tools/greaseNipples',
        image: greaseNipplesImage
    },
    {
        name: 'Other Automobile Workshop Tools',
        path: '/tools/otherAutomobileWorkshopTools',
        image: otherAutomobileWorkshopToolsImage
    }
]

const categoriesToTools = {
    'pliers': pliers,
    'wireCutters': wireCutters,
    'miniTools': miniTools,
    'skcTaps': skcTaps,
    'socketAccessories': socketAccessories,
    'screwdriversAndTesters': screwdriversAndTesters,
    'batteryTerminalHolder': batteryTerminalHolder,
    'airImpactWrench': airImpactWrench,
    'valveSpringCompressor': valveSpringCompressor,
    'oilFilterWrench': oilFilterWrench,
    'dualTireChuck': dualTireChuck,
    'otherAutomobileWorkshopTools': otherAutomobileWorkshopTools,
    'batteryTesters': batteryTesters,
    'greaseNipples': greaseNipples
};

export {categories, categoriesToTools};