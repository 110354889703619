import standardHandTapsOtherImage from "../../../static/images/tools/skcTaps/803.jpg";
import standardHandTapsOtherMeasurements from "../../../static/images/tools/skcTaps/801Measurements.jpg";
import StandardHandTapsOther from "../../../components/ToolDetails/SkcTaps/StandardHandTapsOther/StandardHandTapsOther";

const standardHandTapsOther = {
    name: 'Standard Hand Taps UNC. UNF. NS. BSW BSF',
    image: standardHandTapsOtherImage,
    images: [standardHandTapsOtherImage, standardHandTapsOtherMeasurements],
    modelNos: ["803"],
    company: "SKC Taps",
    madeIn: 'Japan',
    description: [],
    specifications: [
        "Angle of thread [(u)NC]: 60°",
        "Angle of thread [(u)NF]: 60°",
        "Angle of thread [(u)NS]: 60°",
        "Angle of thread [BSW]: 55°",
        "Angle of thread [BSF]: 55°",
    ],
    detail: StandardHandTapsOther,
    path: '/tools/skcTaps/standardHandTapsOther',
    id: 'standardHandTapsOther'
};
export default standardHandTapsOther;