import React from "react";
import {Table} from "react-bootstrap";

export default function CableCutterTaparia() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2">PRODUCT NO.</th>
                <th colSpan="4">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
            </tr>
            <tr>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CC 06</td>
                <td>160</td>
                <td>20</td>
                <td>26</td>
                <td>10</td>
                <td>150</td>
            </tr>
            <tr>
                <td>CC 08</td>
                <td>210</td>
                <td>22</td>
                <td>29</td>
                <td>13</td>
                <td>250</td>
            </tr>
            <tr>
                <td>CC 10</td>
                <td>240</td>
                <td>30</td>
                <td>34</td>
                <td>15</td>
                <td>350</td>
            </tr>
            <tr>
                <td>CCS 10</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <td>CC 18</td>
                <td>460</td>
                <td>32</td>
                <td>58</td>
                <td>16</td>
                <td>1675</td>
            </tr>

            <tr>
                <td>CC 24</td>
                <td>600</td>
                <td>35</td>
                <td>64</td>
                <td>17</td>
                <td>1790</td>
            </tr>
            </tbody>
        </Table>
    </>
}