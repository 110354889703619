import React from "react";
import {Table} from "react-bootstrap";

export default function CoilSpringCompressorSet() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Product No</th>
                <th/>
                <th>Length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>923-2321</td>
                <td><sup>5</sup>&frasl;<sub>8</sub>”-11</td>
                <td>200mm</td>
            </tr>
            <tr>
                <td>923-2322</td>
                <td><sup>5</sup>&frasl;<sub>8</sub>”-11</td>
                <td>300mm</td>
            </tr>
            <tr>
                <td>923-2323</td>
                <td><sup>5</sup>&frasl;<sub>8</sub>”-11</td>
                <td>340mm</td>
            </tr>
            <tr>
                <td>923-2324</td>
                <td><sup>5</sup>&frasl;<sub>8</sub>”-11</td>
                <td>400mm</td>
            </tr>
            </tbody>
        </Table>
    </>
}