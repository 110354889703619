import React from "react";
import {Table} from "react-bootstrap";

export default function PistonRingCompressorWithSafetyValve() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Product No</th>
                <th>Height</th>
                <th>Capacity</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>123-1024</td>
                <td>2”</td>
                <td>1<sup>1</sup>&frasl;<sub>2</sub>” ~ 3<sup>1</sup>&frasl;<sub>2</sub>”</td>
            </tr>
            <tr>
                <td>123-1025</td>
                <td>3<sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td>2<sup>1</sup>&frasl;<sub>4</sub>” ~ 5”</td>
            </tr>
            <tr>
                <td>123-1026</td>
                <td>4”</td>
                <td>2<sup>1</sup>&frasl;<sub>4</sub>” ~ 5”</td>
            </tr>
            <tr>
                <td>123-1027</td>
                <td>3<sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td>3<sup>1</sup>&frasl;<sub>2</sub>” ~ 7”</td>
            </tr>
            <tr>
                <td>123-1028</td>
                <td>4”</td>
                <td>3<sup>1</sup>&frasl;<sub>2</sub>” ~ 7”</td>
            </tr>
            <tr>
                <td>123-1029</td>
                <td>6<sup>1</sup>&frasl;<sub>2</sub>”</td>
                <td>3<sup>1</sup>&frasl;<sub>2</sub>” ~ 7”</td>
            </tr>
            </tbody>
        </Table>
    </>
}