import React from "react";
import {Table} from "react-bootstrap";

export default function MultiPurposeLongNosePliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="8">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Edge length</th>
                <th>Hole diameter</th>
                <th>Tip size</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>MR-150SP</td>
                <td>162</td>
                <td>14</td>
                <td>8</td>
                <td>50</td>
                <td>54</td>
                <td>8</td>
                <td>1.0</td>
                <td>1.5x2.5</td>
                <td>90</td>
                <td>403152</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Brass</th>
                <th>Stranded copper wire [mm²]</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>MR-150SP</td>
                <td>1.6</td>
                <td>2.0</td>
                <td>2.6</td>
                <td>2.0</td>
                <td>3.5mm²</td>
            </tr>
            </tbody>
        </Table>
    </>
}
