import React from 'react';
import {Telephone, Person} from '../Icons/Icons'
import {Col, Image} from 'react-bootstrap';
import PropTypes from 'prop-types';

export default function ContactPerson({name, arabicName, mobileNo, image}) {
    return <Col xs={6} sm={6} md={3} key={name} bsPrefix='padding col'>
        {
            image ?
                <Image src={image} roundedCircle className='w-100' />
            :<Person/>
        }
        <p className="center nospacing">{name}</p>
        {
            arabicName && <p className="Kanz-al-Marjaan contactUs__contactPersonals--arabicName nospacing center">
                {arabicName}
            </p>
        }
        {
            mobileNo && <p className="center contactUs__contactPersonals--mobileNo">
                <a href={`tel:${mobileNo}`}>
                    <Telephone/>
                    {mobileNo}
                </a>
            </p>
        }
    </Col>;
}

ContactPerson.propTypes = {
    name: PropTypes.string.isRequired,
    arabicName: PropTypes.string,
    mobileNo: PropTypes.string
};