import React from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { categoriesToTools } from '../../constants/tools';
import NotFound from '../NotFound/NotFound';

export default function ToolCategory({ match }) {
    const category = match.params.category;
    const tools = categoriesToTools[category]
    if(!tools) {
        return <NotFound />
    }
    return <Container fluid>
        <Row>
            {
                tools.map(t => <Col xl={3} lg={4} md={6} sm={6} xs={12} bsPrefix='Tools col' key={t.name}>
                    <Card className="tiles">
                        <Link to={t.path}>
                            <Card.Body className='Tools__body'>
                                <Card.Text>
                                    <img src={t.image} className='w-100' alt={t.name} />
                                    <div className='Tools__name--category'>
                                        {t.name}
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>)
            }
        </Row>
    </Container>
}