import threeLegsOilFilterWrenchChromePlatedImage from "../../../static/images/tools/oilFilterWrench/215-1082.jpg";

const threeLegsOilFilterWrenchChromePlated = {
    name: 'Three-Legs Oil Filter Wrench (Chrome Plated)',
    image: threeLegsOilFilterWrenchChromePlatedImage,
    images: [threeLegsOilFilterWrenchChromePlatedImage],
    modelNos: ["215-1082"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        '3-jaws design features special reversible jaw design to fit most cars and light trucks.',
        'Chrome Plated'
    ],
    specifications: [
        'Range: 2 1⁄2” ~ 4”',
        'Three Legs Material: Mid-Carbon Steel.',
        'Center Gears Material: Chrome Molybdenum.',
    ],
    detail: () => null,
    path: '/tools/oilFilterWrench/threeLegsOilFilterWrenchChromePlated',
    id: 'threeLegsOilFilterWrenchChromePlated'
};

export default threeLegsOilFilterWrenchChromePlated;