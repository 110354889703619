import snapRingPliersBentJawInternalRingsImage from "../../../static/images/tools/pliers/sib.jpg";
import snapRingPliersBentJawInternalRingsMeasurements
    from "../../../static/images/tools/pliers/sibMeasurements.png";
import SnapRingPliersBentJawInternalRings
    from "../../../components/ToolDetails/Pliers/SnapRingPliersBentJawInternalRings/SnapRingPliersStraightJawInternalRings";

const snapRingPliersBentJawInternalRings = {
    name: 'Snap Ring Pliers Bent-Jaw for Internal Rings',
    image: snapRingPliersBentJawInternalRingsImage,
    images: [snapRingPliersBentJawInternalRingsImage, snapRingPliersBentJawInternalRingsMeasurements],
    modelNos: ["SIB-125P", "SIB-175P", "SIB-230P", "SIB-300P"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For internal snap rings (circlip rings).",
        "High durability and wear resistance are realized by using piano wire for bits.",
        "Tapered bit-basement and non-slip processed bit-surface prevent dropping rings out.",
        "Holding stability improve safety and efficiency of works.",
    ],
    specifications: [
        "Material(Body) | Alloy steel",
        "Material (Bit) | Piano wire",
        "Heat Treatment | Oxide-free heat treatment",
        "Spring | Coil spring",
        "Handle cover | PVC dipping",
    ],
    detail: SnapRingPliersBentJawInternalRings,
    path: '/tools/pliers/snapRingPliersBentJawInternalRings',
    id: 'snapRingPliersBentJawInternalRings'
};
export default snapRingPliersBentJawInternalRings;