import endCuttingPlierImage from "../../../static/images/tools/pliers/en150.jpg";
import endCuttingPlierMeasurements from "../../../static/images/tools/pliers/en150Measurements.png";
import EndCuttingPliers from "../../../components/ToolDetails/Pliers/EndCuttingPliers/EndCuttingPliers";


const endCuttingPlier = {
    name: 'End Cutting Pliers',
    image: endCuttingPlierImage,
    images: [endCuttingPlierImage, endCuttingPlierMeasurements],
    modelNos: ["EN-150"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Suitable for cutting protrusions on the surface such as nails.",
        "Cutting, or pulling out nails and wire with this vertical blade.",
        "For professional works and hobbies.",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C)",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 56～64",
        "Handle cover | PVC dipping",
    ],
    detail: EndCuttingPliers,
    path: '/tools/pliers/endCuttingPliers',
    id: 'endCuttingPliers'
};
export default endCuttingPlier;