import snapRingPliersStraightJawInternalRingsImage from "../../../static/images/tools/pliers/sis.jpg";
import snapRingPliersStraightJawInternalRingsMeasurements
    from "../../../static/images/tools/pliers/sisMeasurements.png";
import SnapRingPliersStraightJawInternalRings
    from "../../../components/ToolDetails/Pliers/SnapRingPliersStraightJawInternalRings/SnapRingPliersStraightJawInternalRings";

const snapRingPliersStraightJawInternalRings = {
    name: 'Snap Ring Pliers Straight-Jaw for Internal Rings',
    image: snapRingPliersStraightJawInternalRingsImage,
    images: [snapRingPliersStraightJawInternalRingsImage, snapRingPliersStraightJawInternalRingsMeasurements],
    modelNos: ["SIS-125P", "SIS-175P", "SIS-230P", "SIS-300P"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For internal snap rings (circlip rings).",
        "High durability and wear resistance are realized by using piano wire for bits.",
        "Tapered bit-basement and non-slip processed bit-surface prevent dropping rings out.",
        "Holding stability improve safety and efficiency of works.",
    ],
    specifications: [
        "Material(Body) | Alloy steel",
        "Material (Bit) | Piano wire",
        "Heat Treatment | Oxide-free heat treatment",
        "Spring | Coil spring",
        "Handle cover | PVC dipping",
    ],
    detail: SnapRingPliersStraightJawInternalRings,
    path: '/tools/pliers/snapRingPliersStraightJawInternalRings',
    id: 'snapRingPliersStraightJawInternalRings'
};
export default snapRingPliersStraightJawInternalRings;