import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function NotFound() {
    return <div className='NotFound'>
        <Container fluid>
            <Row>
                <Col sm={6} xs={12} bsPrefix='NotFound__column col'>
                    404<br />
                    Are you Lost?
                </Col>
                <Col sm={6} xs={12} bsPrefix='Al-Kanz NotFound__column col'>
                    ٤٠٤<br />
                    هل أنت تائه؟
                </Col>
            </Row>
            <Link to='/'>
                <Button variant="outline-light" size="lg">
                    Go to Home
                </Button>
            </Link>
        </Container>
    </div>
}