import React from "react";
import {Table} from "react-bootstrap";

export default function WireRopeCutter() {
   return <>
       <Table striped bordered hover variant="dark" responsive>
           <thead>
           <tr>
 <th rowspan="2">
 </th>
 <th colspan="5">
  Size[mm]
 </th>
 <th rowspan="2">
  Weight
  <br/>
  [g]
 </th>
 <th rowspan="2">
  EAN-code
  <br/>
  (4952269)
 </th>
</tr>
<tr>
 <th>
  Overall length
 </th>
 <th>
  Head width
 </th>
 <th>
  Head thickness
 </th>
 <th>
  Handle width
 </th>
 <th>
  Nose length
 </th>
</tr>

           </thead>
           <tbody>
           <tr>
 <td>
  WC-150
 </td>
 <td>
  167
 </td>
 <td>
  27
 </td>
 <td>
  11
 </td>
 <td>
  42
 </td>
 <td>
  9
 </td>
 <td>
  215
 </td>
 <td>
  108040
 </td>
</tr>

           </tbody>
       </Table>
       <Table striped bordered hover variant="dark" responsive>
           <thead>
           <tr>
 <th>
  Cutting capacity
  <br/>
  (φmm)
 </th>
 <th>
  Wire rope (Iron)
 </th>
 <th>
  Wire rope (Stainless)
 </th>
</tr>

           </thead>
           <tbody>
           <tr>
 <td>
  WC-150
 </td>
 <td>
  4.0
 </td>
 <td>
  3.0
 </td>
</tr>

           </tbody>
       </Table>
   </>
}
