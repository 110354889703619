import React from "react";
import {Table} from "react-bootstrap";

export default function PlasticCuttingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>PN-125</td>
                <td>135</td>
                <td>17</td>
                <td>8</td>
                <td>51</td>
                <td>19</td>
                <td>90</td>
                <td>107012</td>
            </tr>
            <tr>
                <td>PN-150</td>
                <td>162</td>
                <td>19</td>
                <td>8</td>
                <td>49</td>
                <td>21</td>
                <td>110</td>
                <td>107029</td>
            </tr>
            <tr>
                <td>PN-175</td>
                <td>190</td>
                <td>22</td>
                <td>11</td>
                <td>52</td>
                <td>24</td>
                <td>220</td>
                <td>107036</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Plastic</th>
                <th>Copper</th>
                <th>Stranded copper wire [mm²]</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>PN-125</td>
                <td>5.0</td>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <td>PN-150</td>
                <td>5.0</td>
                <td>-</td>
                <td>-</td>
            </tr>
            <tr>
                <td>PN-175</td>
                <td>6.0</td>
                <td>2.0</td>
                <td>5.5</td>
            </tr>
            </tbody>
        </Table>
    </>
}