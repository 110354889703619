import threeLegsOilFilterWrenchBlackOxideImage from "../../../static/images/tools/oilFilterWrench/215-1081.jpg";

const threeLegsOilFilterWrenchBlackOxide = {
    name: 'Three-Legs Oil Filter Wrench (Black Oxide)',
    image: threeLegsOilFilterWrenchBlackOxideImage,
    images: [threeLegsOilFilterWrenchBlackOxideImage],
    modelNos: ["215-1081"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        '3-jaws design features special reversible jaw design to fit most cars and light trucks.',
        'Black Oxide'
    ],
    specifications: [
        'Range: 2 1⁄2” ~ 4”',
        'Three Legs Material: Mid-Carbon Steel.',
        'Center Gears Material: Chrome Molybdenum.',
    ],
    detail: () => null,
    path: '/tools/oilFilterWrench/threeLegsOilFilterWrenchBlackOxide',
    id: 'threeLegsOilFilterWrenchBlackOxide'
};

export default threeLegsOilFilterWrenchBlackOxide;