import React from "react";

export default function StandardHandTapsOther() {
    return <div className='tableFixHead'>
        <table className="table table-dark table-striped table-bordered table-hover">
            <thead>
            <tr>
                <th rowSpan="2">Nominal Diameter (inch)</th>
                <th colSpan="5">Thread per inch</th>
                <th colSpan="2">Length</th>
                <th rowSpan="2">Shank Dia C (mm)</th>
                <th rowSpan="2">Weight <br/> per set <br/> (g) </th>
            </tr>
            <tr>
                <th>(u)NC</th>
                <th>(u)NF</th>
                <th>(u)NS</th>
                <th>BSW</th>
                <th>BSF</th>
                <th>A (mm)</th>
                <th>B (mm)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td><sup>1</sup>&frasl;<sub>16</sub></td>
                <td>60</td>
                <td/>
                <td>64.72</td>
                <td>60</td>
                <td/>
                <td>36</td>
                <td>13</td>
                <td>3</td>
                <td>12</td>
            </tr>
            <tr>
                <td><sup>3</sup>&frasl;<sub>32</sub></td>
                <td>48</td>
                <td/>
                <td>56</td>
                <td>48</td>
                <td/>
                <td>42</td>
                <td>16</td>
                <td>3</td>
                <td>12</td>
            </tr>
            <tr>
                <td><sup>1</sup>&frasl;<sub>8</sub></td>
                <td>40</td>
                <td>44</td>
                <td>40.44</td>
                <td>40</td>
                <td>44</td>
                <td>46</td>
                <td>18</td>
                <td>4</td>
                <td>15</td>
            </tr>
            <tr>
                <td><sup>5</sup>&frasl;<sub>32</sub></td>
                <td>32</td>
                <td>36</td>
                <td>32.36</td>
                <td>32</td>
                <td>36</td>
                <td>52</td>
                <td>20</td>
                <td>5</td>
                <td>20</td>
            </tr>
            <tr>
                <td><sup>3</sup>&frasl;<sub>16</sub></td>
                <td>24</td>
                <td>32</td>
                <td>24.32</td>
                <td>24</td>
                <td>32</td>
                <td>60</td>
                <td>22</td>
                <td>5.5</td>
                <td>30</td>
            </tr>
            <tr>
                <td><sup>7</sup>&frasl;<sub>32</sub></td>
                <td>24</td>
                <td>32</td>
                <td>24.32</td>
                <td>24</td>
                <td/>
                <td>60</td>
                <td>22</td>
                <td>5.5</td>
                <td>35</td>
            </tr>
            <tr>
                <td><sup>1</sup>&frasl;<sub>4</sub></td>
                <td>20</td>
                <td>28</td>
                <td>24.32</td>
                <td>20</td>
                <td>26</td>
                <td>62</td>
                <td>24</td>
                <td>6</td>
                <td>40</td>
            </tr>
            <tr>
                <td><sup>9</sup>&frasl;<sub>32</sub></td>
                <td>20</td>
                <td/>
                <td>28.32</td>
                <td>20</td>
                <td/>
                <td>62</td>
                <td>24</td>
                <td>6</td>
                <td>60</td>
            </tr>
            <tr>
                <td><sup>5</sup>&frasl;<sub>16</sub></td>
                <td>18</td>
                <td>24</td>
                <td>32</td>
                <td>18</td>
                <td>22</td>
                <td>70</td>
                <td>30</td>
                <td>6.1</td>
                <td>70</td>
            </tr>
            <tr>
                <td><sup>3</sup>&frasl;<sub>8</sub></td>
                <td>16</td>
                <td>24</td>
                <td>32</td>
                <td>16</td>
                <td>20</td>
                <td>75</td>
                <td>35</td>
                <td>7</td>
                <td>75</td>
            </tr>
            <tr>
                <td><sup>7</sup>&frasl;<sub>16</sub></td>
                <td>14</td>
                <td>20</td>
                <td>28</td>
                <td>14</td>
                <td>18</td>
                <td>80</td>
                <td>38</td>
                <td>8</td>
                <td>110</td>
            </tr>
            <tr>
                <td><sup>1</sup>&frasl;<sub>2</sub></td>
                <td>13</td>
                <td>20</td>
                <td>32.28</td>
                <td>12</td>
                <td>16</td>
                <td>85</td>
                <td>42</td>
                <td>9</td>
                <td>135</td>
            </tr>
            <tr>
                <td><sup>9</sup>&frasl;<sub>16</sub></td>
                <td>12</td>
                <td>18</td>
                <td>27</td>
                <td>12</td>
                <td>16</td>
                <td>90</td>
                <td>42</td>
                <td>10.5</td>
                <td>185</td>
            </tr>
            <tr>
                <td><sup>5</sup>&frasl;<sub>8</sub></td>
                <td>11</td>
                <td>18</td>
                <td>32</td>
                <td>11</td>
                <td>14</td>
                <td>95</td>
                <td>45</td>
                <td>12</td>
                <td>250</td>
            </tr>
            <tr>
                <td><sup>11</sup>&frasl;<sub>16</sub></td>
                <td>11</td>
                <td>16</td>
                <td>11.16</td>
                <td>11</td>
                <td>14</td>
                <td>95</td>
                <td>45</td>
                <td>12</td>
                <td>350</td>
            </tr>
            <tr>
                <td><sup>3</sup>&frasl;<sub>4</sub></td>
                <td>10</td>
                <td>16</td>
                <td>32</td>
                <td>10</td>
                <td>12</td>
                <td>105</td>
                <td>50</td>
                <td>14</td>
                <td>380</td>
            </tr>
            <tr>
                <td><sup>7</sup>&frasl;<sub>8</sub></td>
                <td>9</td>
                <td>14</td>
                <td>12.18</td>
                <td>9</td>
                <td>11</td>
                <td>115</td>
                <td>55</td>
                <td>17</td>
                <td>600</td>
            </tr>
            </tbody>
        </table>
    </div>
}
