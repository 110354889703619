import multiPurposeLongNosePliersImage from "../../../static/images/tools/miniTools/mr150sp.jpg";
import multiPurposeLongNosePliersMeasurements from "../../../static/images/tools/miniTools/mr150sp.png";
import MultiPurposeLongNosePliers from "../../../components/ToolDetails/MiniTools/MultiPurposeLongNosePliers/MultiPurposeLongNosePliers";

const multiPurposeLongNosePliers = {
    name: 'Multi Purpose Long Nose Pliers',
    image: multiPurposeLongNosePliersImage,
    images: [multiPurposeLongNosePliersImage, multiPurposeLongNosePliersMeasurements],
    description: [
		"Stripping hole on the edge.",
		"Turning screws with serrated round recess.",
    ],
    modelNos: ["MR-150SP"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
		"Material | Carbon steel for machinery",
		"Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
		"Body hardness [HRC] | 42～50",
		"Edge hardness [HRC] | 54～62",
		"Spring | Coil spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: MultiPurposeLongNosePliers,
    path: '/tools/miniTools/multiPurposeLongNosePliers',
    id: 'multiPurposeLongNosePliers'
};

export default multiPurposeLongNosePliers;