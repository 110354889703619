import coilSpringCompressorSetImage from "../../../static/images/tools/otherAutomobileWorkshopTools/923-2321.jpg";
import CoilSpringCompressorSet
    from "../../../components/ToolDetails/OtherAutomobileWorkshopTools/CoilSpringCompressorSet/CoilSpringCompressorSet";

const coilSpringCompressorSet = {
        name: 'Coil Spring Compressor Set',
        image: coilSpringCompressorSetImage,
        images: [coilSpringCompressorSetImage],
        modelNos: ["923-2321", "923-2322", "923-2323", "923-2324"],
        company: "AutoMaster",
        madeIn: 'Taiwan',
        description: [
            'Tempered carbon steel lead screws with economic ductile casting jaws wrap over coil claws for extra safety.',
            'Suitable for use in limited space under wheel arch.',
            'Use with 1⁄2” drive wrench.',
        ],
        specifications: [],
        detail: CoilSpringCompressorSet,
        path: '/tools/otherAutomobileWorkshopTools/coilSpringCompressorSet',
        id: 'coilSpringCompressorSet'
    }
;

export default coilSpringCompressorSet;