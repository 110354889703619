import voltageAndContinuityTester25mAImage from "../../../static/images/tools/batteryTesters/113-1984.jpg";

const voltageAndContinuityTester25mA = {
    name: 'Voltage & Continuity Tester',
    image: voltageAndContinuityTester25mAImage,
    images: [voltageAndContinuityTester25mAImage],
    modelNos: ["113-1984"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        'This economically-priced indicator tests for voltage continuity and polarity.',
        'It’s perfect for solving numerous problems occurring in the household, workshop, auto and hobby areas.',
        'Light-emitting diodes let you check polarity at a glance.',
        'For use with 4.5 to 400 volts, AC or DC.',
        'Tests semi-conductors for continuity.',
        'Powered by 12-volt battery.',
    ],
    specifications: [
        'LED±120~400V',
        '(ED): 30 sec.',
        '1 max: 25mA.',
    ],
    detail: () => null,
    path: '/tools/batteryTesters/voltageAndContinuityTester25mA',
    id: 'voltageAndContinuityTester25mA'
};

export default voltageAndContinuityTester25mA;