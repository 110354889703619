import plasticCuttingPliersWithJawOpeningLimiterImage from "../../../static/images/tools/wireCutters/kt411.jpg";
import plasticCuttingPliersWithJawOpeningLimiterMeasurement
    from "../../../static/images/tools/wireCutters/kt411Measurements.png";
import PlasticCuttingPliersWithJawOpeningLimiter
    from "../../../components/ToolDetails/WireCutters/PlasticCuttingPliersWithJawOpeningLimiter/PlasticCuttingPliersWithJawOpeningLimiter";

const plasticCuttingPliersWithJawOpeningLimiter = {
    name: 'Plastic Cutting Pliers w/ Jaw Opening Limitter',
    image: plasticCuttingPliersWithJawOpeningLimiterImage,
    images: [plasticCuttingPliersWithJawOpeningLimiterImage, plasticCuttingPliersWithJawOpeningLimiterMeasurement],
    modelNos: ["KT-411", "KT-412"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Best for cutting molding gate on the curved surface.",
        "Maximum jaw opening is adjustable with loaded stopper (PAT.).",
        "Stopper reduces surplus movement of handles and improves work efficiency.",
    ],
    specifications: [
        "Material | Alloy steel",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Edge hardness [HRC] | 58～64",
        "Spring | Coil spring",
        "Handle cover | PVC dipping",
    ],
    detail: PlasticCuttingPliersWithJawOpeningLimiter,
    path: '/tools/wireCutters/plasticCuttingPliersWithJawOpeningLimiter',
    id: 'plasticCuttingPliersWithJawOpeningLimiter'
};

export default plasticCuttingPliersWithJawOpeningLimiter;