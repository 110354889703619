import React from "react";
import {Table} from "react-bootstrap";

export default function PipeTapsAmericanStandards() {
    return <Table striped bordered hover variant="dark" responsive>
        <thead>
        <tr>
            <th rowSpan='2'> Nominal Diameter<br/> (INCH)</th>
            <th rowSpan='2'>Thread Per Inch</th>
            <th rowSpan='2'>Basic Pitch Diameter</th>
            <th colSpan="4">Regular</th>
            <th rowSpan="2">Weight <br/> Per Pc<br/>(g)</th>
            <th rowSpan="2">Number of Flutes</th>
        </tr>
        <tr>
            <th>A (mm)</th>
            <th>B (mm)</th>
            <th>B1 (mm)</th>
            <th>C (mm)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td><sup>1</sup>&frasl;<sub>16</sub></td>
            <td>27</td>
            <td>7.142</td>
            <td>55</td>
            <td>17.5</td>
            <td>11.99</td>
            <td>8</td>
            <td>15</td>
            <td>4</td>
        </tr>
        <tr>
            <td><sup>1</sup>&frasl;<sub>8</sub></td>
            <td>27</td>
            <td>9.489</td>
            <td>55</td>
            <td>19</td>
            <td>12.03</td>
            <td>8</td>
            <td>20</td>
            <td>4</td>
        </tr>
        <tr>
            <td><sup>1</sup>&frasl;<sub>4</sub></td>
            <td>18</td>
            <td>12.487</td>
            <td>62</td>
            <td>28</td>
            <td>17.44</td>
            <td>11</td>
            <td>40</td>
            <td>4</td>
        </tr>
        <tr>
            <td><sup>3</sup>&frasl;<sub>8</sub></td>
            <td>18</td>
            <td>15.926</td>
            <td>65</td>
            <td>28</td>
            <td>17.63</td>
            <td>14</td>
            <td>80</td>
            <td>4</td>
        </tr>
        <tr>
            <td><sup>1</sup>&frasl;<sub>2</sub></td>
            <td>14</td>
            <td>19.772</td>
            <td>80</td>
            <td>35</td>
            <td>22.83</td>
            <td>18</td>
            <td>140</td>
            <td>4</td>
        </tr>
        <tr>
            <td><sup>3</sup>&frasl;<sub>4</sub></td>
            <td>14</td>
            <td>25.117</td>
            <td>85</td>
            <td>35</td>
            <td>22.96</td>
            <td>23</td>
            <td>240</td>
            <td>5</td>
        </tr>
        <tr>
            <td>1</td>
            <td>11 <sup>1</sup>&frasl;<sub>2</sub></td>
            <td>31.461</td>
            <td>95</td>
            <td>45</td>
            <td>27.38</td>
            <td>26</td>
            <td>400</td>
            <td>5</td>
        </tr>
        <tr>
            <td>1 <sup>1</sup>&frasl;<sub>4</sub></td>
            <td>11 <sup>1</sup>&frasl;<sub>2</sub></td>
            <td>40.218</td>
            <td>105</td>
            <td>45</td>
            <td>28.09</td>
            <td>32</td>
            <td>650</td>
            <td>5</td>
        </tr>
        <tr>
            <td>1 <sup>1</sup>&frasl;<sub>2</sub></td>
            <td>11 <sup>1</sup>&frasl;<sub>2</sub></td>
            <td>46.287</td>
            <td>110</td>
            <td>45</td>
            <td>28.42</td>
            <td>38</td>
            <td>890</td>
            <td>7</td>
        </tr>
        </tbody>
    </Table>
}
