import cableCutterWithStripperImage from "../../../static/images/tools/wireCutters/cs22.jpg";
import cableCutterWithStripperMeasurements from "../../../static/images/tools/wireCutters/cs22Measurements.png";
import CableCutterWithStripper
    from "../../../components/ToolDetails/WireCutters/CableCutterWithStripper/CableCutterWithStripper";

const cableCutter = {
    name: 'Cable Cutter with Stripper',
    image: cableCutterWithStripperImage,
    images: [cableCutterWithStripperImage, cableCutterWithStripperMeasurements],
    modelNos: ["CS-22"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Stripper opening adjuster loaded.",
        "Stripping edge on the tip is suitable for continuous work.",
    ],
    specifications: [
        "Material | Alloy steel",
        "Heat Treatment | Oxide-free heat treatment",
        "Hardness | 54～62",
        "Handle cover | PVC dipping",
    ],
    detail: CableCutterWithStripper,
    path: '/tools/wireCutters/cableCutterWithStripper',
    id: 'cableCutterWithStripper'
};

export default cableCutter;