import strapWrench from "./oilFilterWrench/strapWrench";
import threeLegsOilFilterWrenchChromePlated from "./oilFilterWrench/threeLegsOilFilterWrenchChromePlated";
import threeLegsOilFilterWrenchBlackOxide from "./oilFilterWrench/threeLegsOilFilterWrenchBlackOxide";

const oilFilterWrench = [
    strapWrench,
    threeLegsOilFilterWrenchChromePlated,
    threeLegsOilFilterWrenchBlackOxide
]

export default oilFilterWrench;