import React from "react";
import {Table} from "react-bootstrap";

export default function GreaseNipplesMetricSize140pcs() {
    return <>
        <h4>Types of Metrics in kit</h4>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th className='center'>Angle of Hose</th>
                <th colSpan='3'>0° (Straight)</th>
                <th colSpan='3'>90°</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>Size</th>
                <td><sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td><sup>5</sup>&frasl;<sub>16</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>8</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td><sup>5</sup>&frasl;<sub>16</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>8</sub>”</td>
            </tr>
            <tr>
                <th>Thread Type</th>
                <td>UNF</td>
                <td>UNF</td>
                <td>GAS</td>
                <td>UNF</td>
                <td>UNF</td>
                <td>GAS</td>
            </tr>
            </tbody>
        </Table>
    </>
}