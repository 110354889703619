import wideJawDiagonalCuttingPliersImage from "../../../static/images/tools/wireCutters/cn130.jpg";
import wideJawDiagonalCuttingPliersMeasurement from "../../../static/images/tools/wireCutters/cn130Measurements.png";
import WideJawDiagonalCuttingPliers
    from "../../../components/ToolDetails/WireCutters/WideJawDiagonalCuttingPliers/WideJawDiagonalCuttingPliers";

const wideJawDiagonalCuttingPliers = {
    name: 'Wide Jaw Diagonal Cutting Pliers',
    image: wideJawDiagonalCuttingPliersImage,
    images: [wideJawDiagonalCuttingPliersImage, wideJawDiagonalCuttingPliersMeasurement],
    modelNos: ["WN-180"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Cutting various materials.",
        "For stainless, iron wire or 3 core flat cables (VVF cable).",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C）",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 58～64",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: WideJawDiagonalCuttingPliers,
    path: '/tools/wireCutters/wideJawDiagonalCuttingPliers',
    id: 'wideJawDiagonalCuttingPliers'
};

export default wideJawDiagonalCuttingPliers;