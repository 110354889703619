import flatScrewDriversImage from "../../../static/images/tools/screwdriversAndTesters/flatsScrewdriver.jpg";
import flatScrewDriversMeasurements
    from "../../../static/images/tools/screwdriversAndTesters/flatsScrewdriverMeasurements.jpg";
import FlatScrewDrivers from "../../../components/ToolDetails/ScrewdriversAndTesters/FlatScrewDrivers/FlatScrewDrivers";

const flatScrewDrivers = {
        name: 'Flat Screw Drivers',
        image: flatScrewDriversImage,
        images: [flatScrewDriversImage, flatScrewDriversMeasurements],
        modelNos: ["932", "933", "911", "901", "902", "912", "842", "913", "712", "833", "713", "936", "921", "922", "923", "824", "825", "826", "924", "925", "926", "827", "828", "829", "725", "927", "928", "929", "930", "931"],
        company: "Taparia",
        madeIn: 'India',
        description: [
            "The blade is made from high grade Silicon - Manganese steel which no other make uses. This steel gives spring effect also.",
            "The blade is differently hardened and tempered to resist wear, bending and meet high torque requirement. Other makes offer same hardness through out the length of the blade, which is not scientific.",
            "Hardness on tip : 55 to 58 HRC. Other makes offer much less hardness on tip. Higher the hardness higher is wear resistance.",
            "The blade tip is magnetised to lift small screw etc.from confined spaces or to hold the the screw in position.",
        ],
        specifications: [],
        detail: FlatScrewDrivers,
        path: '/tools/screwdriversAndTesters/flatScrewDrivers',
        id: 'flatScrewDrivers'
    }
;
export default flatScrewDrivers;