import React from "react";
import {Table} from "react-bootstrap";

export default function LineTesters() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan='2'>Product No</th>
                <th rowSpan='2'>Handle <br/> Colour</th>
                <th colSpan='5'> Size [mm]</th>
                <th rowSpan='2'>Weight <br/> (g)</th>
            </tr>
            <tr>
                <th>Tip Size <br/>B x T</th>
                <th>A</th>
                <th>C</th>
                <th>D</th>
                <th>L</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>813</td>
                <td className='text-warning font-weight-bold'>Yellow</td>
                <td>3.5 x 0.4</td>
                <td>55</td>
                <td>17</td>
                <td>6</td>
                <td>130</td>
                <td>25</td>
            </tr>
            <tr>
                <td>814</td>
                <td className='text-success font-weight-bold'>Green</td>
                <td>3.5 x 0.5</td>
                <td>60</td>
                <td>14</td>
                <td>6</td>
                <td>125</td>
                <td>20</td>
            </tr>
            <tr>
                <td>815</td>
                <td className='text-success font-weight-bold'>Green</td>
                <td>3.5 x 0.5</td>
                <td>100</td>
                <td>18</td>
                <td>6</td>
                <td>180</td>
                <td>35</td>
            </tr>
            <tr>
                <td>816</td>
                <td className='text-warning font-weight-bold'>Yellow</td>
                <td>3.5 x 0.5</td>
                <td>95</td>
                <td>17</td>
                <td>6</td>
                <td>170</td>
                <td>35</td>
            </tr>
            <tr>
                <td>818</td>
                <td className='text-warning font-weight-bold'>Yellow</td>
                <td>SQ.POKER</td>
                <td>60</td>
                <td>14</td>
                <td>6</td>
                <td>125</td>
                <td>20</td>
            </tr>
            <tr>
                <td>873</td>
                <td className='text-warning font-weight-bold'>Yellow</td>
                <td>4.0 X 0.6</td>
                <td>114</td>
                <td>18</td>
                <td>4</td>
                <td>200</td>
                <td>30</td>
            </tr>
            </tbody>
        </Table>
    </>
}