import React from "react";
import {Table} from "react-bootstrap";

export default function CableCutterSmall() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CA-22</td>
                <td>162</td>
                <td>25</td>
                <td>10</td>
                <td>47</td>
                <td>18</td>
                <td>140</td>
                <td>108019</td>
            </tr>
            <tr>
                <td>CA-38</td>
                <td>210</td>
                <td>30</td>
                <td>13</td>
                <td>48</td>
                <td>20</td>
                <td>250</td>
                <td>108026</td>
            </tr>
            <tr>
                <td>CA-60</td>
                <td>237</td>
                <td>34</td>
                <td>14</td>
                <td>47</td>
                <td>26</td>
                <td>330</td>
                <td>108033</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Copper core cable</th>
                <th>IV Cable</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CA-22</td>
                <td>9.0</td>
                <td>22</td>
            </tr>
            <tr>
                <td>CA-38</td>
                <td>11.0</td>
                <td>38</td>
            </tr>
            <tr>
                <td>CA-60</td>
                <td>13.0</td>
                <td>60</td>
            </tr>
            </tbody>
        </Table>
    </>
}