import heavyDutyAirImpactWrenchImage from "../../../static/images/tools/airImpactWrench/663-5080.jpg";

const heavyDutyAirImpactWrench = {
    name: '3⁄4" Heavy Duty Air Impact Wrench',
    image: heavyDutyAirImpactWrenchImage,
    images: [heavyDutyAirImpactWrenchImage],
    modelNos: ["663-5080"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [
        'Square Drive: 3⁄4"',
        'Bolt Capacity: 1” (25.4mm)',
        'Free Speed (RPM): 4,500',
        'Max. Torque (FT-LB): 500',
        'Sound Level dB (A): 91',
        'Vibration (ᵐ⁄ₛ₂): 4.2',
        'Air Pressure: 90PSI',
        'Air Inlet (NPT) : 1⁄4"',
        'Air Cons. (CFM): 7',
    ],
    detail: () => null,
    path: '/tools/airImpactWrench/heavyDutyAirImpactWrench',
    id: 'heavyDutyAirImpactWrench'
};

export default heavyDutyAirImpactWrench;