import miniBatteryHydrometerImage from "../../../static/images/tools/otherAutomobileWorkshopTools/111-3109.jpg";

const miniBatteryHydrometer = {
    name: 'Mini Battery Hydrometer',
    image: miniBatteryHydrometerImage,
    images: [miniBatteryHydrometerImage],
    modelNos: ["111-3109"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        'Tests the battery in seconds.',
        'The number of balls floating indicates battery condition.',
    ],
    specifications: [],
    detail: () => null,
    path: '/tools/otherAutomobileWorkshopTools/miniBatteryHydrometer',
    id: 'miniBatteryHydrometer'
};

export default miniBatteryHydrometer;