import React from "react";
import {Table} from "react-bootstrap";

export default function DualTireChuckWithRubber() {
    return <Table striped bordered hover variant="dark" responsive>
        <thead>
            <tr>
                <th>Product No</th>
                <th>Size</th>
            </tr>
        </thead>
        <tbody>
        <tr>
            <td>600-1126</td>
            <td>10”</td>
        </tr>
        <tr>
            <td>600-1137</td>
            <td>10”</td>
        </tr>
        <tr>
            <td>600-1122</td>
            <td>12”</td>
        </tr>
        <tr>
            <td>600-1138</td>
            <td>14”</td>
        </tr>
        <tr>
            <td>600-1139</td>
            <td>14 <sup>1</sup>&frasl;<sub>2</sub>”</td>
        </tr>
        </tbody>
    </Table>
}