import miniCutterImage from "../../../static/images/tools/miniTools/wc150.jpg";
import miniCutterMeasurements from "../../../static/images/tools/miniTools/wc150.png";
import MiniCutter from "../../../components/ToolDetails/MiniTools/MiniCutter/MiniCutter";

const miniCutter = {
    name: 'Mini Cutter',
    image: miniCutterImage,
    images: [miniCutterImage, miniCutterMeasurements],
    description: [
		"For plastic and thin wire.",
		"Thin and narrow head is suitable for fine works like electronics or miniature making.",
    ],
    modelNos: ["MC-125"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
		"Material | Carbon steel for machinery",
		"Heat Treatment | Oxide-free heat treatment",
		"Hardness | 46～52",
		"Spring | Torsion spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: MiniCutter,
    path: '/tools/miniTools/miniCutter',
    id: 'miniCutter'
};

export default miniCutter;