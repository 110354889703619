import inspectionWorkingLampImage from "../../../static/images/tools/otherAutomobileWorkshopTools/119-2041.jpg";

const inspectionWorkingLamp = {
    name: 'Inspection Working Lamp',
    image: inspectionWorkingLampImage,
    images: [inspectionWorkingLampImage],
    modelNos: ["119-2041"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [
        'PVC handle, install type.',
        '24Ft. wire.',
        '12V 10Amp battery clip.',
        'Use 12V or 24V 50W bulb.',
        'Size: 340 x 110mm.',
    ],
    detail: () => null,
    path: '/tools/otherAutomobileWorkshopTools/inspectionWorkingLamp',
    id: 'inspectionWorkingLamp'
};

export default inspectionWorkingLamp;