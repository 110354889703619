import React from "react";
import {Table} from "react-bootstrap";

export default function CombinationPlier() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2">PROD. NO</th>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
            </tr>
            <tr>
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>L</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1621-6</td>
                <td>165</td>
                <td>33</td>
                <td>12</td>
                <td>23.0</td>
                <td>10.5</td>
                <td>185</td>
            </tr>
            <tr>
                <td>1621-7</td>
                <td>185</td>
                <td>33</td>
                <td>12</td>
                <td>23.0</td>
                <td>11.5</td>
                <td>240</td>
            </tr>
            <tr>
                <td>1621-8</td>
                <td>210</td>
                <td>39</td>
                <td>16</td>
                <td>27.5</td>
                <td>12.0</td>
                <td>325</td>
            </tr>
            <tr>
                <td>1611-6</td>
                <td>165</td>
                <td>35</td>
                <td>13</td>
                <td>25</td>
                <td>10.0</td>
                <td>170</td>
            </tr>
            <tr>
                <td>1611-7</td>
                <td>185</td>
                <td>38</td>
                <td>15</td>
                <td>27</td>
                <td>10.5</td>
                <td>220</td>
            </tr>
            <tr>
                <td>1611-8</td>
                <td>200</td>
                <td>40</td>
                <td>17</td>
                <td>30</td>
                <td>12.0</td>
                <td>285</td>
            </tr>
            <tr>
                <td>1601-6</td>
                <td>165</td>
                <td>35</td>
                <td>13</td>
                <td>25</td>
                <td>10.0</td>
                <td>190</td>
            </tr>
            <tr>
                <td>1601-7</td>
                <td>185</td>
                <td>38</td>
                <td>15</td>
                <td>27</td>
                <td>10.5</td>
                <td>220</td>
            </tr>
            <tr>
                <td>1601-8</td>
                <td>200</td>
                <td>40</td>
                <td>15</td>
                <td>28</td>
                <td>12.0</td>
                <td>310</td>
            </tr>
            <tr>
                <td>MCP-10</td>
                <td>255</td>
                <td>-</td>
                <td>-</td>
                <td>40</td>
                <td>15.0</td>
                <td>540</td>
            </tr>
            <tr>
                <td>MCP-12</td>
                <td>300</td>
                <td>-</td>
                <td>-</td>
                <td>35</td>
                <td>15.0</td>
                <td>620</td>
            </tr>
            </tbody>
        </Table>
    </>
}