import pistonRingCompressorWithSafetyValveImage from "../../../static/images/tools/otherAutomobileWorkshopTools/123-1024.jpg";
import PistonRingCompressorWithSafetyValve
    from "../../../components/ToolDetails/OtherAutomobileWorkshopTools/PistonRingCompressorWithSafetyValve/PistonRingCompressorWithSafetyValve";

const pistonRingCompressorWithSafetyValve = {
    name: 'Piston Ring Compressor With Safety Valve',
    image: pistonRingCompressorWithSafetyValveImage,
    images: [pistonRingCompressorWithSafetyValveImage],
    modelNos: ["123-1024", "123-1025", "123-1026", "123-1027", "123-1028", "123-1029"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        'Ratchet type complete with Hex spanner.',
        'With safety valve.',
    ],
    specifications: [],
    detail: PistonRingCompressorWithSafetyValve,
    path: '/tools/otherAutomobileWorkshopTools/pistonRingCompressorWithSafetyValve',
    id: 'pistonRingCompressorWithSafetyValve'
};

export default pistonRingCompressorWithSafetyValve;