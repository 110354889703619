import longNosePlierImage from "../../../static/images/tools/pliers/rp150.jpg";
import longNosePlierMeasurements from "../../../static/images/tools/pliers/rp150Measurements.png";
import LongNosePliers from "../../../components/ToolDetails/Pliers/LongNosePliers/LongNosePliers";

const longNosePlier = {
    name: 'Long Nose Pliers',
    image: longNosePlierImage,
    images: [longNosePlierImage, longNosePlierMeasurements],
    modelNos: ["RP-125", "RP-150", "RP-200"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For cutting wire and bending metal plates.",
        "*RP-125/200 are not JIS certified because JIS standard for long nose pliers exists only 150mm size.",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C)",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 54～62",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: LongNosePliers,
    path: '/tools/pliers/longNosePliers',
    id: 'longNosePliers'
};
export default longNosePlier;