import React from "react";
import {Table} from "react-bootstrap";

export default function TelescopingMagneticPickUpTool() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Product No</th>
                <th>Shape</th>
                <th>Dimensions <br/> in inch</th>
                <th>Dimensions <br/> in cm</th>
                <th>Telescope Range <br/> in inch</th>
                <th>Telescope Range <br/> in cm</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>299-1094</td>
                <td>Rectangle</td>
                <td>3<sup>1</sup>&frasl;<sub>2</sub>” x 2”</td>
                <td>8.9cm x 5.1cm</td>
                <td>12” to 17<sup>1</sup>&frasl;<sub>2</sub>”</td>
                <td>30.5cm to 44.5cm</td>
            </tr>
            <tr>
                <td>299-1095</td>
                <td>Circle</td>
                <td>Diameter: 2<sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td>Diameter: 5.7cm</td>
                <td>5<sup>1</sup>&frasl;<sub>8</sub>” to 25<sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td>13cm to 64cm</td>
            </tr>
            <tr>
                <td>299-1096</td>
                <td>Circle</td>
                <td>Diameter: 1<sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td>Diameter: 3.2cm</td>
                <td>8<sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td>21cm</td>
            </tr>
            </tbody>
        </Table>
    </>
}