import React from "react";
import {Table} from "react-bootstrap";

export default function StandardHandTapsMetricStandard() {
    return <>
        <h4>Metric (COARSE)</h4>
        <div className='tableFixHead'>
            <table className="table table-dark table-striped table-bordered table-hover">
                <thead>
                <tr>
                    <th rowSpan="2">Nominal Diameter (mm)</th>
                    <th rowSpan="2">Pitch(mm)</th>
                    <th colSpan="2">Length</th>
                    <th rowSpan="2">Shank Dia C (mm)</th>
                    <th rowSpan="2">Weight <br/> (g)</th>
                </tr>
                <tr>
                    <th>A(mm)</th>
                    <th>B(mm)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>2.6</td>
                    <td>0.45</td>
                    <td>44</td>
                    <td>16</td>
                    <td>3</td>
                    <td>12</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>0.5</td>
                    <td>46</td>
                    <td>18</td>
                    <td>4</td>
                    <td>18</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>0.7</td>
                    <td>52</td>
                    <td>20</td>
                    <td>5</td>
                    <td>20</td>
                </tr>
                <tr>
                    <td>4.5</td>
                    <td>0.75</td>
                    <td>55</td>
                    <td>20</td>
                    <td>5</td>
                    <td>25</td>
                </tr>
                <tr>
                    <td>5</td>
                    <td>0.8</td>
                    <td>60</td>
                    <td>22</td>
                    <td>5.5</td>
                    <td>30</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>1</td>
                    <td>62</td>
                    <td>24</td>
                    <td>6</td>
                    <td>40</td>
                </tr>
                <tr>
                    <td>8</td>
                    <td>1.25</td>
                    <td>70</td>
                    <td>30</td>
                    <td>6.2</td>
                    <td>70</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>1.5</td>
                    <td>75</td>
                    <td>32</td>
                    <td>7</td>
                    <td>80</td>
                </tr>
                <tr>
                    <td>12</td>
                    <td>1.75</td>
                    <td>82</td>
                    <td>38</td>
                    <td>8.5</td>
                    <td>135</td>
                </tr>
                <tr>
                    <td>14</td>
                    <td>2</td>
                    <td>88</td>
                    <td>42</td>
                    <td>10.5</td>
                    <td>185</td>
                </tr>
                <tr>
                    <td>16</td>
                    <td>2</td>
                    <td>95</td>
                    <td>45</td>
                    <td>12.5</td>
                    <td>250</td>
                </tr>
                <tr>
                    <td>18</td>
                    <td>2.5</td>
                    <td>100</td>
                    <td>48</td>
                    <td>14</td>
                    <td>360</td>
                </tr>
                <tr>
                    <td>20</td>
                    <td>2.5</td>
                    <td>105</td>
                    <td>50</td>
                    <td>15</td>
                    <td>400</td>
                </tr>
                <tr>
                    <td>22</td>
                    <td>2.5</td>
                    <td>115</td>
                    <td>55</td>
                    <td>17</td>
                    <td>650</td>
                </tr>
                <tr>
                    <td>24</td>
                    <td>3</td>
                    <td>120</td>
                    <td>58</td>
                    <td>19</td>
                    <td>850</td>
                </tr>
                <tr>
                    <td>27</td>
                    <td>3</td>
                    <td>130</td>
                    <td>62</td>
                    <td>20</td>
                    <td>1180</td>
                </tr>
                </tbody>
            </table>
        </div>
        <h4>Ingredient Specification</h4>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2">Type Of Steel</th>
                <th colSpan="11">Element's Chemical Composition</th>
            </tr>
            <tr>
                <th>C % X 100</th>
                <th>Sl % X 100</th>
                <th>Mn % X 100</th>
                <th>P % X 1000</th>
                <th>S % X 1000</th>
                <th>Cu % X 100</th>
                <th>Ni % X 100</th>
                <th>Cr % X 100</th>
                <th>Ma % X 100</th>
                <th>W % X 100</th>
                <th>V % X 100</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SKH<sub>2</sub></td>
                <td>70-85</td>
                <td>MAX 40</td>
                <td>MAX 40</td>
                <td>MAX 30</td>
                <td>MAX 30</td>
                <td/>
                <td/>
                <td>380-450</td>
                <td/>
                <td>1700-1900</td>
                <td>80-120</td>
            </tr>
            <tr>
                <td>SKH<sub>9</sub></td>
                <td>80-90</td>
                <td>MAX 40</td>
                <td>MAX 40</td>
                <td>MAX 30</td>
                <td>MAX 30</td>
                <td/>
                <td/>
                <td>380-450</td>
                <td>450-550</td>
                <td>550-670</td>
                <td>160-220</td>
            </tr>
            <tr>
                <td>SKS<sub>11</sub></td>
                <td>120-130</td>
                <td>MAX 35</td>
                <td>MAX 50</td>
                <td>MAX 30</td>
                <td>MAX 30</td>
                <td/>
                <td/>
                <td>20-50</td>
                <td/>
                <td>300-400</td>
                <td>10-30</td>
            </tr>
            <tr>
                <td>SKS<sub>2</sub></td>
                <td>100-110</td>
                <td>MAX 35</td>
                <td>MAX 80</td>
                <td>MAX 30</td>
                <td>MAX 30</td>
                <td/>
                <td/>
                <td>50-100</td>
                <td/>
                <td>100-150</td>
                <td/>
            </tr>
            <tr>
                <td>GSS<sub>2</sub></td>
                <td>97</td>
                <td>MAX 18</td>
                <td>MAX 97</td>
                <td>MAX 24</td>
                <td>MAX 17</td>
                <td>9</td>
                <td/>
                <td>48</td>
                <td>21</td>
                <td/>
                <td/>
            </tr>
            <tr>
                <td>ALLOY</td>
                <td>0.95-1.10</td>
                <td>MAX 35</td>
                <td>MAX 90</td>
                <td>MAX 27</td>
                <td>MAX 20</td>
                <td/>
                <td/>
                <td>13-165</td>
                <td/>
                <td/>
                <td/>
            </tr>
            </tbody>
        </Table>
    </>
}
