import slimPlasticCutterRoundBladeImage from "../../../static/images/tools/wireCutters/snp145r.jpg";
import slimPlasticCutterRoundBladeMeasurement from "../../../static/images/tools/wireCutters/snp145rMeasurements.png";
import SlimPlasticCutterRoundBlade
    from "../../../components/ToolDetails/WireCutters/SlimPlasticCutterRoundBlade/SlimPlasticCutterRoundBlade";

const slimPlasticCutterRoundBlade = {
    name: 'Slim Plastic Cutter Round-blade',
    image: slimPlasticCutterRoundBladeImage,
    images: [slimPlasticCutterRoundBladeImage, slimPlasticCutterRoundBladeMeasurement],
    modelNos: ["SNP-145R", "SNP-165R"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Slim head reaches target inside narrow space.",
        "Flat blade is suitable for cutting targets on the curved surface.",
        "Long-life torsion spring loaded.",
    ],
    specifications: [
        "Material | Alloy steel",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Edge hardness [HRC] | 58～64",
        "Spring | Torsion spring",
        "Handle cover | PVC dipping",
    ],
    detail: SlimPlasticCutterRoundBlade,
    path: '/tools/wireCutters/slimPlasticCutterRoundBlade',
    id: 'slimPlasticCutterRoundBlade'
};

export default slimPlasticCutterRoundBlade;