import React from "react";
import {Table} from "react-bootstrap";

export default function LightWeightAluminiumHandleCableCutter() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CA-450AL</td>
                <td>450</td>
                <td>70</td>
                <td>15</td>
                <td>114</td>
                <td>35</td>
                <td>1,040</td>
                <td>111170</td>
            </tr>
            <tr>
                <td>CA-600AL</td>
                <td>605</td>
                <td>74</td>
                <td>16</td>
                <td>145</td>
                <td>44</td>
                <td>1,740</td>
                <td>111187</td>
            </tr>
            <tr>
                <td>CA-800AL</td>
                <td>805</td>
                <td>84</td>
                <td>17</td>
                <td>163</td>
                <td>66</td>
                <td>2,790</td>
                <td>111194</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Copper core cable</th>
                <th>IV Cable</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CA-450AL</td>
                <td>21.0</td>
                <td>150</td>
            </tr>
            <tr>
                <td>CA-600AL</td>
                <td>26.0</td>
                <td>250</td>
            </tr>
            <tr>
                <td>CA-800AL</td>
                <td>39.0</td>
                <td>500</td>
            </tr>
            </tbody>
        </Table>
    </>
}