import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { categories } from "../../constants/tools";

export default function Tools() {
    return <Container fluid>
        <Row>
            {
                categories.map(category => <Col xl={3} lg={4} md={6} sm={6} xs={12} bsPrefix='Tools col' key={category.name}>
                    <Card className="tiles">
                        <Link to={category.path}>
                            <Card.Body className='Tools__body'>
                                <Card.Text>
                                    <img src={category.image} className='w-100' alt={category.name} />
                                    <div className='Tools__name'>
                                        {category.name}
                                    </div>
                                </Card.Text>
                            </Card.Body>
                        </Link>
                    </Card>
                </Col>)
            }
        </Row>
    </Container>
}