import React from "react";
import {Table} from "react-bootstrap";

export default function SideCuttingPlierEurope() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="6">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Hole diameter</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>TP-150</td>
                <td>161</td>
                <td>23</td>
                <td>11</td>
                <td>50</td>
                <td>33</td>
                <td>1.8</td>
                <td>190</td>
                <td>106015</td>
            </tr>
            <tr>
                <td>TP-175</td>
                <td>178</td>
                <td>25</td>
                <td>12</td>
                <td>52</td>
                <td>35</td>
                <td>1.8</td>
                <td>255</td>
                <td>106022</td>
            </tr>
            <tr>
                <td>TP-200</td>
                <td>205</td>
                <td>28</td>
                <td>13</td>
                <td>53</td>
                <td>37</td>
                <td>1.8</td>
                <td>350</td>
                <td>106039</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <td>Cutting capacity (φmm)</td>
                <td>Stainless</td>
                <td>Soft iron</td>
                <td>Copper</td>
                <td>Stranded copper wire [mm²]</td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>TP-150</td>
                <td>1.6</td>
                <td>2.6</td>
                <td>3.2</td>
                <td>8.0</td>
            </tr>
            <tr>
                <td>TP-175</td>
                <td>1.8</td>
                <td>2.6</td>
                <td>3.6</td>
                <td>8.0</td>
            </tr>
            <tr>
                <td>TP-200</td>
                <td>2.6</td>
                <td>3.2</td>
                <td>4.0</td>
                <td>8.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}