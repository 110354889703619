import React from "react";
import {Table} from "react-bootstrap";

export default function SideCuttingPlier() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="6">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
                <th>Nose length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CP-150</td>
                <td>163</td>
                <td>22</td>
                <td>12.5</td>
                <td>49</td>
                <td>14</td>
                <td>26</td>
                <td>205</td>
                <td>101027</td>
            </tr>
            <tr>
                <td>CP-175</td>
                <td>186</td>
                <td>24</td>
                <td>13.5</td>
                <td>51</td>
                <td>16</td>
                <td>29</td>
                <td>275</td>
                <td>101034</td>
            </tr>
            <tr>
                <td>CP-200</td>
                <td>211</td>
                <td>27</td>
                <td>14.5</td>
                <td>52</td>
                <td>19</td>
                <td>34</td>
                <td>360</td>
                <td>101041</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Stranded copper wire [mm²]</th>
                <th>VVF cable</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CP-150</td>
                <td>1.6</td>
                <td>2.6</td>
                <td>3.2</td>
                <td>8.0</td>
                <td>1.6 x 3 cores</td>
            </tr>
            <tr>
                <td>CP-175</td>
                <td>2.0</td>
                <td>2.6</td>
                <td>3.6</td>
                <td>8.0</td>
                <td>1.6 x 3 cores</td>
            </tr>
            <tr>
                <td>CP-200</td>
                <td>2.6</td>
                <td>3.2</td>
                <td>4.0</td>
                <td>8.0</td>
                <td>2.0 x 3 cores</td>
            </tr>
            </tbody>
        </Table>
    </>
}