import pipeTapsAmericanStandardImage from "../../../static/images/tools/skcTaps/pipeTapsBS.jpg";
import pipeTapsAmericanStandardNPS from "../../../static/images/tools/skcTaps/NPS.jpg";
import pipeTapsAmericanStandardNPT from "../../../static/images/tools/skcTaps/NPT.jpg";
import PipeTapsAmericanStandards
    from "../../../components/ToolDetails/SkcTaps/PipeTapsAmericanStandards/PipeTapsAmericanStandards";

const pipeTapsAmericanStandard = {
    name: 'Pipe Taps (American Standard)',
    image: pipeTapsAmericanStandardImage,
    images: [pipeTapsAmericanStandardImage, pipeTapsAmericanStandardNPS, pipeTapsAmericanStandardNPT],
    modelNos: ["804NT", "804PF", "805NS", "804SF"],
    company: "SKC Taps",
    madeIn: 'Japan',
    description: [
        "NPT: American National Taper Pipe Thread",
        "NPTF: Dryseal American National Taper Pipe Thread",
        "NPS: American National Straight Pipe Thread",
        "NPSF: Dryseal American National Straight Pipe Thread",
    ],
    specifications: [],
    detail: PipeTapsAmericanStandards,
    path: '/tools/skcTaps/pipeTapsAmericanStandard',
    id: 'pipeTapsAmericanStandard'
};
export default pipeTapsAmericanStandard;