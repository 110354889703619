import voltageAndContinuityTesterImage from "../../../static/images/tools/batteryTesters/113-1990.jpg";

const voltageAndContinuityTester = {
    name: 'Voltage & Continuity Tester',
    image: voltageAndContinuityTesterImage,
    images: [voltageAndContinuityTesterImage],
    modelNos: ["113-1990"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        'This economically-priced indicator tests for voltage continuity and polarity.',
        'It’s perfect for solving numerous problems occurring in the household, workshop, auto and hobby areas.',
        'Light-emitting diodes let you check polarity at a glance.',
        'Tests semi-conductors for continuity.',
        'Powered by 12-volt battery.',
        'Tests continuity from 0 to 20 KΩ.',
        'For use with 6 to 400 volts, AC or DC.',
    ],
    specifications: [
        'LED 6V~50V=LED.',
        '120, 230, 400V=neon light.',
        '(ED): 30 sec.',
        '1 max: 30mA.',
        'Continuity: 0......20k',
    ],
    detail: () => null,
    path: '/tools/batteryTesters/voltageAndContinuityTester',
    id: 'voltageAndContinuityTester'
};

export default voltageAndContinuityTester;