import standardHandTapsMetricStandard from "./skcTaps/standardHandTapsMetricStandard";
import standardHandTapsOther from "./skcTaps/standardHandTapsOther";
import pipeTapsBritishStandard from "./skcTaps/pipeTapsBritishStandard";
import pipeTapsAmericanStandard from "./skcTaps/pipeTapsAmericanStandard";

const skcTaps = [
    standardHandTapsMetricStandard,
    standardHandTapsOther,
    pipeTapsBritishStandard,
    pipeTapsAmericanStandard
]

export default skcTaps