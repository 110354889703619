import phillipsScrewDriverImage from "../../../static/images/tools/screwdriversAndTesters/phillips.jpg";
import phillipsScrewDriverMeasurements
    from "../../../static/images/tools/screwdriversAndTesters/phillipsMeasurements.jpg";
import PhillipsScrewDriver
        from "../../../components/ToolDetails/ScrewdriversAndTesters/PhillipsScrewDriver/PhillipsScrewDriver";

const phillipsScrewDriver = {
        name: 'Phillips Screw Drivers',
        image: phillipsScrewDriverImage,
        images: [phillipsScrewDriverImage, phillipsScrewDriverMeasurements],
        modelNos: ["P3 859 50", "P3 860 60", "P3 860 150", "P5 861 75", "P5 861 100", "P5 861 150", "P5 861 200", "P6 862 100", "P6 862 150", "P6 862 200", "P6 862 250", "P6 862 300", "P8 863 150", "P8 863 200", "P8 863 250", "P8 863 300", "P1 864 200"],
        company: "Taparia",
        madeIn: 'India',
        description: [],
        specifications: [],
        detail: PhillipsScrewDriver,
        path: '/tools/screwdriversAndTesters/phillipsScrewDriver',
        id: 'phillipsScrewDriver'
    }
;
export default phillipsScrewDriver;