import slipJointPlierImage from "../../../static/images/tools/pliers/pl150.jpg";
import slipJointPlierMeasurementImage from "../../../static/images/tools/pliers/pl150Measurements.jpg"
import SlipJointPliers from "../../../components/ToolDetails/Pliers/SlipJointPliers/SlipJointPliers";

const slipJointPlier = {
    name: 'Slip Joint Pliers',
    image: slipJointPlierImage,
    images: [slipJointPlierImage, slipJointPlierMeasurementImage],
    modelNos: ["PL-150", "PL-200"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
        "Material | Carbon steel for machinery（S55C）",
        "Heat Treatment | Oxide-free heat treatment",
        "Body hardness [HRC] | 47～55",
        "Handle cover | PVC dipping",
        "Surface treatment | Nickel-chrome plating",
    ],
    detail: SlipJointPliers,
    description: [
        'Two type of jaw position can be chose with slip joint.',
        'Wide jaw position for holding large target.',
        'Cutting function is available with narrow jaw position.'
    ],
    path: '/tools/pliers/slipJointPliers',
    id: 'slipJointPliers',
};
export default slipJointPlier;