import React from "react";
import {Table} from "react-bootstrap";

export default function WideJawDiagonalCuttingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>WN-180</td>
                <td>182</td>
                <td>29</td>
                <td>12</td>
                <td>50</td>
                <td>23</td>
                <td>220</td>
                <td>110753</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Brass</th>
                <th>Stranded copper wire [mm²]</th>
                <th>VVF cable</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>WN-180</td>
                <td>2.0</td>
                <td>2.6</td>
                <td>3.6</td>
                <td>3.0</td>
                <td>8.0</td>
                <td>1.6 x 3 cores</td>
            </tr>
            </tbody>
        </Table>
    </>
}