import shearingPlierImage from "../../../static/images/tools/pliers/sp200.jpg";
import shearingPlierMeasurements from "../../../static/images/tools/pliers/sp200Measurements.png";
import ShearingPliers from "../../../components/ToolDetails/Pliers/ShearingPliers/ShearingPliers";

const shearingPliers = {
    name: 'Shearing Pliers',
    image: shearingPlierImage,
    images: [shearingPlierImage, shearingPlierMeasurements],
    modelNos: ["SP-200"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For bending and cutting both of wire and metal sheets.",
        "Jaw side edge for metal sheets and cables.",
        "Handle side edge for wire.",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C）",
        "Heat Treatment | Oxide-free heat treatment",
        "Body hardness [HRC] | 44～52",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: ShearingPliers,
    path: '/tools/pliers/shearingPliers',
    id: 'shearingPliers'
};
export default shearingPliers;