import automotiveMultiTester from "./batteryTesters/automotiveMultiTester";
import carCheck from "./batteryTesters/carCheck";
import voltageAndContinuityTester from "./batteryTesters/voltageAndContinuityTester";
import voltageAndContinuityTester25mA from "./batteryTesters/voltageAndContinuityTester25mA";

const batteryTesters = [
    automotiveMultiTester,
    carCheck,
    voltageAndContinuityTester,
    voltageAndContinuityTester25mA
]

export default batteryTesters;