import React from "react";
import {Table} from "react-bootstrap";

export default function SlipJointPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="6">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>PL-150</td>
                <td>167</td>
                <td>30</td>
                <td>9</td>
                <td>44</td>
                <td>31</td>
                <td>7</td>
                <td>165</td>
                <td>106060</td>
            </tr>
            <tr>
                <td>PL-200</td>
                <td>203</td>
                <td>31</td>
                <td>11</td>
                <td>46</td>
                <td>35</td>
                <td>7</td>
                <td>270</td>
                <td>106077</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Soft iron</th>
                <th>Annealed iron</th>
                <th>Max jaw opening</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>PL-150</td>
                <td>2.0</td>
                <td>2.0</td>
                <td>20</td>
            </tr>
            <tr>
                <td>PL-200</td>
                <td>2.6</td>
                <td>2.6</td>
                <td>25</td>
            </tr>
            </tbody>
        </Table>
    </>
}