import React from "react";

export default function PhillipsScrewDriver() {
    return <div className='tableFixHead'>
        <table className="table table-dark table-striped table-bordered table-hover">
            <thead>
            <tr>
                <th>Product No</th>
                <th>TIP SIZE <br/> B x T</th>
                <th>A</th>
                <th>C</th>
                <th>D</th>
                <th>L</th>
                <th>Weight <br/> (g)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>P3 859 50</td>
                <td>0</td>
                <td>50</td>
                <td>13</td>
                <td>3</td>
                <td>108</td>
                <td>15</td>
            </tr>
            <tr>
                <td>P3 860 60</td>
                <td>0</td>
                <td>60</td>
                <td>17</td>
                <td>3</td>
                <td>135</td>
                <td>20</td>
            </tr>
            <tr>
                <td>P3 860 150</td>
                <td>0</td>
                <td>150</td>
                <td>17</td>
                <td>3</td>
                <td>225</td>
                <td>25</td>
            </tr>
            <tr>
                <td>P5 861 75</td>
                <td>1</td>
                <td>75</td>
                <td>22</td>
                <td>3</td>
                <td>160</td>
                <td>50</td>
            </tr>
            <tr>
                <td>P5 861 100</td>
                <td>1</td>
                <td>100</td>
                <td>22</td>
                <td>5</td>
                <td>185</td>
                <td>55</td>
            </tr>
            <tr>
                <td>P5 861 150</td>
                <td>1</td>
                <td>150</td>
                <td>22</td>
                <td>5</td>
                <td>235</td>
                <td>60</td>
            </tr>
            <tr>
                <td>P5 861 200</td>
                <td>1</td>
                <td>200</td>
                <td>22</td>
                <td>5</td>
                <td>285</td>
                <td>65</td>
            </tr>
            <tr>
                <td>P6 862 100</td>
                <td>2</td>
                <td>100</td>
                <td>22</td>
                <td>6</td>
                <td>198</td>
                <td>80</td>
            </tr>
            <tr>
                <td>P6 862 150</td>
                <td>2</td>
                <td>150</td>
                <td>26</td>
                <td>6</td>
                <td>248</td>
                <td>95</td>
            </tr>
            <tr>
                <td>P6 862 200</td>
                <td>2</td>
                <td>200</td>
                <td>26</td>
                <td>6</td>
                <td>298</td>
                <td>110</td>
            </tr>
            <tr>
                <td>P6 862 250</td>
                <td>2</td>
                <td>250</td>
                <td>26</td>
                <td>6</td>
                <td>348</td>
                <td>125</td>
            </tr>
            <tr>
                <td>P6 862 300</td>
                <td>2</td>
                <td>300</td>
                <td>26</td>
                <td>6</td>
                <td>398</td>
                <td>140</td>
            </tr>
            <tr>
                <td>P8 863 150</td>
                <td>3</td>
                <td>150</td>
                <td>28</td>
                <td>8</td>
                <td>255</td>
                <td>155</td>
            </tr>
            <tr>
                <td>P8 863 200</td>
                <td>3</td>
                <td>200</td>
                <td>28</td>
                <td>8</td>
                <td>305</td>
                <td>175</td>
            </tr>
            <tr>
                <td>P8 863 250</td>
                <td>3</td>
                <td>250</td>
                <td>28</td>
                <td>8</td>
                <td>355</td>
                <td>195</td>
            </tr>
            <tr>
                <td>P8 863 300</td>
                <td>3</td>
                <td>300</td>
                <td>28</td>
                <td>8</td>
                <td>405</td>
                <td>215</td>
            </tr>
            <tr>
                <td>P1 864 200</td>
                <td>4</td>
                <td>200</td>
                <td>34</td>
                <td>10</td>
                <td>327</td>
                <td>250</td>
            </tr>
            </tbody>
        </table>
    </div>
}
