import halfInchAirImpactWrenchImage from "../../../static/images/tools/airImpactWrench/663-5040.jpg";

const halfInchAirImpactWrench = {
    name: '1⁄2” Air Impact Wrench',
    image: halfInchAirImpactWrenchImage,
    images: [halfInchAirImpactWrenchImage],
    modelNos: ["663-5040"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [
        'Square Drive: 1⁄2"',
        'Bolt Capacity: 5⁄8" (16mm)',
        'Free Speed (RPM): 7,000',
        'Max. Torque (FT-LB): 260',
        'Sound Level dB (A): 94',
        'Vibration (ᵐ⁄ₛ₂): 10.4',
        'Air Pressure: 90PSI',
        'Air Inlet (NPT): 1⁄4"',
        'Avg. Air Cons. (CFM): 4.5',
    ],
    detail: () => null,
    path: '/tools/airImpactWrench/halfInchAirImpactWrench',
    id: 'halfInchAirImpactWrench'
};

export default halfInchAirImpactWrench;