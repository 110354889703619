import carCheckImage from "../../../static/images/tools/batteryTesters/113-1959.jpg";

const carCheck = {
    name: 'Car-Check',
    image: carCheckImage,
    images: [carCheckImage],
    modelNos: ["113-1959"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        'Voltage display: 6, 12, 24, 48V.',
        'LED display shows polarity.',
    ],
    specifications: [],
    detail: () => null,
    path: '/tools/batteryTesters/carCheck',
    id: 'carCheck'
};

export default carCheck;