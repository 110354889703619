import greaseNipplesMetricSize80pcsImage from "../../../static/images/tools/greaseNipples/GE-853.jpg";
import GreaseNipplesMetricSize80pcs
    from "../../../components/ToolDetails/GreaseNipples/GreaseNipplesInchSize80pcs/GreaseNipplesMetricSize80pcs";

;

const greaseNipplesMetricSize80pcs = {
    name: '80pcs Grease Nipples (Metric Size)',
    image: greaseNipplesMetricSize80pcsImage,
    images: [greaseNipplesMetricSize80pcsImage],
    modelNos: ["GE-853"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: ["Kit contains 12 popular & useful metric sizes."],
    specifications: [],
    detail: GreaseNipplesMetricSize80pcs,
    path: '/tools/greaseNipples/greaseNipplesMetricSize80pcs',
    id: 'greaseNipplesMetricSize80pcs'
}

export default greaseNipplesMetricSize80pcs;