import socketSetImage from "../../../static/images/tools/socketAccessories/501-0418.jpg";

const socketSet = {
    name: '18 Pcs, 1⁄2″ Dr. Socket Set',
    image: socketSetImage,
    images: [socketSetImage],
    modelNos: ['501-0418'],
    company: 'AutoMaster',
    madeIn: 'Taiwan',
    description: [
        "Sliding T-Bar.",
        "Ratchet Handle.",
        "Extension Bar : 125mm, 250mm.",
        '14Pcs, 1⁄2″Dr. Socket : 10, 11, 12, 13, 14, 15, 16, 17, 19, 21, 24, 27, 30, 32mm.',
    ],
    specifications: [],
    detail: () => null,
    path: '/tools/socketAccessories/socketSet',
    id: 'socketSet'
};

export default socketSet;