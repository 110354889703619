import diagonalCuttingPlier from "./wireCutters/diagonalCuttingPliers";
import diagonalCuttingPlierWithStripper from "./wireCutters/diagonalCuttingPliersWithStripper";
import wideJawDiagonalCuttingPliers from "./wireCutters/wideJawDiagonalCuttingPliers";
import plasticCuttingPliers from "./wireCutters/plasticCuttingPliers";
import slimPlasticCutterFlatBlade from "./wireCutters/slimPlasticCutterFlatBlade";
import slimPlasticCutterRoundBlade from "./wireCutters/slimPlasticCutterRoundBlade";
import plasticCuttingPliersWithJawOpeningLimiter from "./wireCutters/plasticCuttingPliersWithJawOpeningLimiter";
import lightWeightAluminiumHandleCableCutter from "./wireCutters/lightWeightAluminiumHandleCableCutter";
import cableCutter from "./wireCutters/cableCutter";
import cableCutterSmall from "./wireCutters/cableCutterSmall";
import cableCutterWithStripper from "./wireCutters/cableCutterWithStripper";
import wireRopeCutter from "./wireCutters/wireRopeCutter";
import wireRopeCutterwithLock from "./wireCutters/wireRopeCutterwithLock";
import cableCutterTaparia from "./wireCutters/cableCutterTaparia";
import tinCutterWithSpring from "./wireCutters/tinCutterWithSpring";
import aviationTinCutterWithSpring from "./wireCutters/aviationTinCutterWithSpring";
import terminalCrimpingTool from "./wireCutters/terminalCrimpingTool";
import terminalCrimpingToolBigger from "./wireCutters/terminalCrimpingToolBigger";

const wireCutters = [
    diagonalCuttingPlier,
    diagonalCuttingPlierWithStripper,
    wideJawDiagonalCuttingPliers,
    plasticCuttingPliers,
    slimPlasticCutterFlatBlade,
    slimPlasticCutterRoundBlade,
    plasticCuttingPliersWithJawOpeningLimiter,
    lightWeightAluminiumHandleCableCutter,
    cableCutter,
    cableCutterSmall,
    cableCutterWithStripper,
    wireRopeCutter,
    wireRopeCutterwithLock,
    cableCutterTaparia,
    tinCutterWithSpring,
    aviationTinCutterWithSpring,
    terminalCrimpingTool,
    terminalCrimpingToolBigger,
];

export default wireCutters;