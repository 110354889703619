import wireRopeCutterImage from "../../../static/images/tools/wireCutters/wc-150.jpg";
import wireRopeCutterMeasurements from "../../../static/images/tools/wireCutters/wc-150.png";
import WireRopeCutter from "../../../components/ToolDetails/WireCutters/WireRopeCutter/WireRopeCutter";

const wireRopeCutter = {
    name: 'Wire Rope Cutter',
    image: wireRopeCutterImage,
    images: [wireRopeCutterImage, wireRopeCutterMeasurements],
    modelNos: ["WC-150"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
		"One-action unlock system loaded.",
		"Ready to use with single grasping after taking up from the tool sack.",
		"Cutting wire rope cleanly, without becoming loose.",
    ],
    specifications: [
		"Material | Alloy steel",
		"Heat Treatment | Induction hardening on edge",
		"Edge hardness [HRC] | 54～62",
		"Spring | Torsion spring",
		"Handle cover | PVC dipping",
    ],
    detail: WireRopeCutter,
    path: '/tools/wireCutters/wireRopeCutter',
    id: 'wireRopeCutter'
};

export default wireRopeCutter;