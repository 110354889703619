import cableCutterTapariaImage from "../../../static/images/tools/wireCutters/cableCutterTaparia.jpg";
import cableCutterTapariaMeasurements from "../../../static/images/tools/wireCutters/cableCutterTapariaMeasurements.jpg";
import CableCutterTaparia from "../../../components/ToolDetails/WireCutters/CableCutterTaparia/CableCutterTaparia";

const cableCutterTaparia = {
    name: 'Cable Cutter',
    image: cableCutterTapariaImage,
    images: [cableCutterTapariaImage, cableCutterTapariaMeasurements],
    modelNos: ["CC 06", "CC 08", "CC 10", "CCS 10", "CC 18", "CC 24"],
    company: "Taparia",
    madeIn: 'India',
    description: [
        "Drop forged and differentially hardened and tempered. Cutting edges are sharp and precision machined to appropriate angle to cut the cables with ease.Higher hardness of 55 to 60 HRC on the cutting edges enables long cutting edge life. Dip coated sleeves provide cushioning grip."
    ],
    specifications: [],
    detail: CableCutterTaparia,
    path: '/tools/wireCutters/cableCutterTaparia',
    id: 'cableCutterTaparia'
};

export default cableCutterTaparia;