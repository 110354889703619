import valveSpringCompressorImage from "../../../static/images/tools/valveSpringCompressor/123-1201.jpg";

const valveSpringCompressor = {
    name: 'Valve Spring Compressor',
    image: valveSpringCompressorImage,
    images: [valveSpringCompressorImage],
    modelNos: ["123-1201"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [
        'Size: 10”',
        'Throat Depth: 242mm.',
        'Jaw Opening: 66mm ~ 194mm.',
        'Compresses valve springs on overhead valve engines.',
    ],
    detail: () => null,
    path: '/tools/valveSpringCompressor/valveSpringCompressor',
    id: 'valveSpringCompressor'
};

export default valveSpringCompressor;