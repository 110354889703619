import { Container, Carousel, Card, Col, Row } from "react-bootstrap";
import shop1 from '../../static/images/shop1.jpeg';
import shop2 from '../../static/images/shop2.jpeg';
import shop3 from '../../static/images/shop3.jpeg';

export default function Home() {
    return (<Container fluid>
        <Row>
            <Col lg={8} md={12} s={12}>
                <Carousel wrap={true}>
                    <Carousel.Item>
                        <img
                            className="s-block w-100"
                            src={shop1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={shop2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100"
                            src={shop3}
                            alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Col>
            <Col lg={4} md={12} sm={12} bsPrefix='padding col'>
                <Card className="tiles">
                    <Card.Body>
                        <Card.Title>About Us </Card.Title>
                        <Card.Text>
                            We are wholesale and distributor of hand tools and hardware and power tools. We are in industrial area where we can approach all the end users specially for the furniture, electrical and aluminium industries.
                    </Card.Text>
                    </Card.Body>
                </Card>

            </Col>
        </Row>
    </Container>)
}