import bentNosePliersImage from "../../../static/images/tools/miniTools/rb125.jpg";
import bentNosePliersMeasurements from "../../../static/images/tools/miniTools/rb125.png";
import BentNosePliers from "../../../components/ToolDetails/MiniTools/BentNosePliers/BentNosePliers";

const bentNosePliers = {
    name: 'Bent Nose Pliers',
    image: bentNosePliersImage,
    images: [bentNosePliersImage, bentNosePliersMeasurements],
    modelNos: ["RB-125"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
		"Bent type nose is convenient for works on the desk.",
    ],
    specifications: [
		"Material | Carbon steel for machinery",
		"Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
		"Body hardness [HRC] | 42～50",
		"Edge hardness [HRC] | 54～62",
		"Spring | Coil spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: BentNosePliers,
    path: '/tools/miniTools/bentNosePliers',
    id: 'bentNosePliers'
};

export default bentNosePliers;