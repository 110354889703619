import batteryClipWithVinylBoot from "./otherAutomobileWorkshopTools/batteryClipWithVinylBoot";
import miniBatteryHydrometer from "./otherAutomobileWorkshopTools/miniBatteryHydrometer";
import inspectionWorkingLamp from "./otherAutomobileWorkshopTools/inspectionWorkingLamp";
import pistonRingCompressorWithSafetyValve from "./otherAutomobileWorkshopTools/pistonRingCompressorWithSafetyValve";
import coilSpringCompressorSet from "./otherAutomobileWorkshopTools/coilSpringCompressorSet";
import telescopingMagneticPickUpTool from "./otherAutomobileWorkshopTools/telescopingMagneticPickUpTool";

const otherAutomobileWorkshopTools = [
    batteryClipWithVinylBoot,
    miniBatteryHydrometer,
    inspectionWorkingLamp,
    pistonRingCompressorWithSafetyValve,
    coilSpringCompressorSet,
    telescopingMagneticPickUpTool,
]

export default otherAutomobileWorkshopTools;