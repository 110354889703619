import React from "react";
import {Table} from "react-bootstrap";

export default function GreaseNipplesInchSize150pcs() {
    return <>
        <h4>Types of Metrics in kit</h4>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th className='center'>Angle of Hose</th>
                <th colSpan='4'>0° (Straight)</th>
                <th colSpan='4'>90°</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>Thickness</th>
                <td>6mm</td>
                <td>8mm</td>
                <td>8mm</td>
                <td>10mm</td>
                <td>6mm</td>
                <td>8mm</td>
                <td>8mm</td>
                <td>10mm</td>
            </tr>
            <tr>
                <th>Pitch</th>
                <td>1mm</td>
                <td>1mm</td>
                <td>1.25mm</td>
                <td>1mm</td>
                <td>1mm</td>
                <td>1mm</td>
                <td>1.25mm</td>
                <td>1mm</td>
            </tr>
            </tbody>
        </Table>
    </>
}