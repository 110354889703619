import { Col, Row, Container, Card } from 'react-bootstrap';
import { Telephone, Map, Envelope } from '../Icons/Icons'
import ContactPersonals from '../../constants/contactPersonals';
import ContactPerson from '../ContactPerson/ContactPerson'

export default function ContactUs() {
    return (
        <Container fluid>
            <Row>
                <Col md={4}>
                    <Card className="tiles">
                        <Card.Body>
                            <Card.Title>Shop Details </Card.Title>
                            <Card.Text>
                                <p>
                                    Shop No-9, Ground Floor, Khalifa Jasim Building,<br />
                            Khalifa Jasim Street,<br />
                            Shuwaikh Industrial Area,<br />
                            Kuwait.<br />
                                </p>
                                <p>
                                    <a target='_blank' rel="noreferrer" href="https://www.google.com/maps/dir//Hakimi+establishment+hardware+kuwait/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3fcf9bbd627e78c9:0x50d15e67c0076f35?sa=X&ved=2ahUKEwi8rsOZvZPtAhUcxDgGHd66Ag0Q9RcwAHoECAUQBA">
                                        <Map />
                                        <span className='contactUs__iconText'>Get Directions</span>
                                    </a>
                                </p>
                                <p>
                                    <a href="tel:+96524847051">
                                        <Telephone />
                                        <span className='contactUs__iconText'>+965 2484 7051</span>
                                    </a>
                                </p>
                                <p>
                                    <a href="mailto:hakimiest@gmail.com">
                                        <Envelope />
                                        <span className='contactUs__iconText'>hakimiest@gmail.com</span>
                                    </a>
                                </p>
                                <p>
                                    <a href="mailto:abbasibawaji@hotmail.com">
                                        <Envelope />
                                        <span className='contactUs__iconText'>abbasibawaji@hotmail.com</span>
                                    </a>
                                </p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8} bsPrefix='padding col'>
                    <Card className="tiles">
                        <Card.Body>
                            <Card.Title>You May also contact </Card.Title>
                            <Card.Text>
                                <Row>
                                    {
                                        ContactPersonals.map((person) =>
                                            <ContactPerson {...person} key={person.name}/>)
                                    }
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

