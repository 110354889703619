import React from "react";
import {Table} from "react-bootstrap";

export default function WireRopeCutterwithLock() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>WC-200</td>
                <td>202</td>
                <td>28</td>
                <td>13</td>
                <td>53</td>
                <td>12</td>
                <td>345</td>
                <td>108125</td>
            </tr>
            <tr>
                <td>WC-225</td>
                <td>228</td>
                <td>28</td>
                <td>13</td>
                <td>56</td>
                <td>12</td>
                <td>390</td>
                <td>113433</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity<br/>(φmm)</th>
                <th>Wire rope (Iron)</th>
                <th>Wire rope (Stainless)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>WC-200</td>
                <td>5.0</td>
                <td>4.0</td>
            </tr>
            <tr>
                <td>WC-225</td>
                <td>6.0</td>
                <td>5.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}
