import dualTireChuckWithHoseBarbImage from "../../../static/images/tools/dualTireChuck/600-1134.jpg";

const dualTireChuckWithHoseBarb = {
    name: 'Dual Tire Chuck With Hose Barb',
    image: dualTireChuckWithHoseBarbImage,
    images: [dualTireChuckWithHoseBarbImage],
    modelNos: ["600-1134"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [],
    detail: () => null,
    path: '/tools/dualTireChuck/dualTireChuckWithHoseBarb',
    id: 'dualTireChuckWithHoseBarb'
}

export default dualTireChuckWithHoseBarb;