import slantEdgeCuttingPlierImage from "../../../static/images/tools/pliers/np125.jpg";
import slantEdgeCuttingPlierMeasurements from "../../../static/images/tools/pliers/np125Measurements.png";
import SlantEdgeCuttingPliers from "../../../components/ToolDetails/Pliers/SlantEdgeCuttingPliers/SlantEdgeCuttingPliers";

const slantEdgeCuttingPlier = {
    name: 'Slant Edge Cutting Pliers',
    image: slantEdgeCuttingPlierImage,
    images: [slantEdgeCuttingPlierImage, slantEdgeCuttingPlierMeasurements],
    modelNos: ["NP-125", "NP-150"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
        "Material | Carbon steel for machinery（S58C)",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 54～62",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: SlantEdgeCuttingPliers,
    description: [
        "Suitable for cutting targets at the closest point from walls.",
        "Clean cut surface.",
        "Stripping hole loaded on the edge.",
        "Also for removing staples using the tip of edge.",
    ],
    path: '/tools/pliers/slantEdgeCuttingPliers',
    id: 'slantEdgeCuttingPliers',
}

export default slantEdgeCuttingPlier;