import lineTestersImage from "../../../static/images/tools/screwdriversAndTesters/lineTesters.jpg";
import lineTestersMeasurements
    from "../../../static/images/tools/screwdriversAndTesters/lineTestersMeasurements.jpg";
import LineTesters from "../../../components/ToolDetails/ScrewdriversAndTesters/LineTesters/LineTesters";

const lineTesters = {
    name: 'Line Testers',
    image: lineTestersImage,
    images: [lineTestersImage, lineTestersMeasurements],
    modelNos: ["813", "814", "815", "816", "818", "873"],
    company: "Taparia",
    madeIn: 'India',
    description: [
        "Generally Conforming to IS 5579-1985",
        "TAPARIA® Line Tester has high quality blade and cellulose acetate plastic handle. Fully insulated blade ensures shock proof working. Distinct glow of Neon lamp identifies line ‘live’. Features a clip for safe carrying in pocket. Available in several sizes.",
    ],
    specifications: [],
    detail: LineTesters,
    path: '/tools/screwdriversAndTesters/lineTesters',
    id: 'lineTesters'
};
export default lineTesters;