import automotiveMultiTesterImage from "../../../static/images/tools/batteryTesters/113-1957.jpg";

const automotiveMultiTester = {
    name: 'Automotive Multi-Tester',
    image: automotiveMultiTesterImage,
    images: [automotiveMultiTesterImage],
    modelNos: ["113-1957"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        'Voltage, Battery, Alternator multi-checker for all 12V DC systems.',
        'With a "Magnetic Board" on the back side to fix on suitable metal sheet of car easily.',
        '6 LED indicators to show battery charge situation and high or low output of alternator.',
    ],
    specifications: [],
    detail: () => null,
    path: '/tools/batteryTesters/automotiveMultiTester',
    id: 'automotiveMultiTester'
};

export default automotiveMultiTester;