import strapWrenchImage from "../../../static/images/tools/oilFilterWrench/215-1576.jpg";

const strapWrench = {
    name: 'Strap Wrench',
    image: strapWrenchImage,
    images: [strapWrenchImage],
    modelNos: ["215-1576"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [
        'Non-slip strap.',
        'Universal application for automobiles, tractors & trucks.',
    ],
    specifications: [
        'Size: 40 ~ 240mm.',
    ],
    detail: () => null,
    path: '/tools/oilFilterWrench/strapWrench',
    id: 'strapWrench'
};

export default strapWrench;