import React from "react";
import {Table} from "react-bootstrap";

export default function SnapRingPliersStraightJawInternalRings() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">JAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Handle width</th>
                <th>Head thickness</th>
                <th>Nose length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SIS-125P</td>
                <td>152</td>
                <td>18</td>
                <td>53</td>
                <td>8</td>
                <td>28</td>
                <td>105</td>
                <td>110104</td>
            </tr>
            <tr>
                <td>SIS-175P</td>
                <td>180</td>
                <td>21</td>
                <td>53</td>
                <td>10</td>
                <td>33</td>
                <td>150</td>
                <td>110142</td>
            </tr>
            <tr>
                <td>SIS-230P</td>
                <td>240</td>
                <td>23</td>
                <td>59</td>
                <td>11</td>
                <td>40</td>
                <td>255</td>
                <td>110180</td>
            </tr>
            <tr>
                <td>SIS-300P</td>
                <td>305</td>
                <td>29</td>
                <td>58</td>
                <td>12</td>
                <td>54</td>
                <td>475</td>
                <td>113549</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th/>
                <th>Bit diameter : (φmm)</th>
                <th>Usable range（mm)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SIS-125P</td>
                <td>1.4</td>
                <td>12～25</td>
            </tr>
            <tr>
                <td>SIS-175P</td>
                <td>1.8</td>
                <td>19～60</td>
            </tr>
            <tr>
                <td>SIS-230P</td>
                <td>2.3</td>
                <td>32～80</td>
            </tr>
            <tr>
                <td>SIS-300P</td>
                <td>3.0</td>
                <td>80～165</td>
            </tr>
            </tbody>
        </Table>
    </>
}