import batteryClipWithVinylBootImage from "../../../static/images/tools/otherAutomobileWorkshopTools/111-6513.jpg";

const batteryClipWithVinylBoot = {
    name: 'Battery Clip With Vinyl Boot',
    image: batteryClipWithVinylBootImage,
    images: [batteryClipWithVinylBootImage],
    modelNos: ["111-6513"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [
        '5 Amp'
    ],
    detail: () => null,
    path: '/tools/otherAutomobileWorkshopTools/batteryClipWithVinylBoot',
    id: 'batteryClipWithVinylBoot'
};

export default batteryClipWithVinylBoot;