import React from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Nav from "../Nav/NavBar.jsx";
import ContactUs from '../ContactUs/ContactUs.jsx'
import Home from '../Home/Home.jsx';
import Tools from '../Tools/Tools.jsx';
import NotFound from '../NotFound/NotFound.jsx';
import ToolCategory from '../ToolCategory/ToolCategory.jsx';
import Tool from "../Tool/Tool";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Search from "../Search/Search";

function App() {

  return (
    <Router>
      <ScrollToTop/>
      <Nav />
      <div className='App__container'>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/contactus' component={ContactUs} />
          <Route exact path='/tools' component={Tools} />
          <Route exact path='/tools/:category' component={ToolCategory} />
          <Route exact path='/tools/:category/:tool' component={Tool} />
          <Route exact path='/search' component={Search} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
