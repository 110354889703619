import snapRingPliersBentJawExternalRingsImage from "../../../static/images/tools/pliers/seb.jpg";
import snapRingPliersBentJawExternalRingsMeasurements
    from "../../../static/images/tools/pliers/sebMeasurements.png";
import SnapRingPliersBentJawExternalRings
    from "../../../components/ToolDetails/Pliers/SnapRingPliersBentJawExternalRings/SnapRingPliersBentJawExternalRings";

const snapRingPliersBentJawExternalRings = {
    name: 'Snap Ring Pliers Bent-Jaw for External Rings',
    image: snapRingPliersBentJawExternalRingsImage,
    images: [snapRingPliersBentJawExternalRingsImage, snapRingPliersBentJawExternalRingsMeasurements],
    modelNos: ["SEB-125P", "SEB-175P", "SEB-230P", "SEB-300P"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For external snap rings (circlip rings).",
        "High durability and wear resistance are realized by using piano wire for bits.",
        "Tapered bit-basement and non-slip processed bit-surface prevent dropping rings out.",
        "Holding stability improve safety and efficiency of works.",
    ],
    specifications: [
        "Material(Body) | Alloy steel",
        "Material (Bit) | Piano wire",
        "Heat Treatment | Oxide-free heat treatment",
        "Spring | Coil spring",
        "Handle cover | PVC dipping",
    ],
    detail: SnapRingPliersBentJawExternalRings,
    path: '/tools/pliers/snapRingPliersBentJawExternalRings',
    id: 'snapRingPliersBentJawExternalRings'
};
export default snapRingPliersBentJawExternalRings;