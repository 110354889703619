const ContactPersonals = [
    {
        name: 'Mr. Johar Palodawala',
        arabicName: 'جوهار پالوداوالا',
        mobileNo: '+965 6616 9051'
    },
    {
        name: 'Mr. Hussain Palodawala',
        arabicName: 'حسين پالوداوالا',
        mobileNo: '+965 9098 2794'
    },
    {
        name: 'Mr. Vinod Patel',
        arabicName: 'وينود پٹيل',
        mobileNo: '+965 9752 1328'
    }
]

export default ContactPersonals