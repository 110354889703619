import React from "react";
import {Table} from "react-bootstrap";

export default function DuckBillPlier() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="6">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Tip size</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>DBP-200</td>
                <td>207</td>
                <td>17</td>
                <td>10</td>
                <td>50</td>
                <td>41</td>
                <td>6.0x3.0</td>
                <td>180</td>
                <td>106053</td>
            </tr>
            </tbody>
        </Table>
    </>
}