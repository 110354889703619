import React from "react";
import {Table} from "react-bootstrap";

export default function SlantEdgeCuttingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="8">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Edge length</th>
                <th>Hole diameter</th>
                <th>Head angle</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>NP-125</td>
                <td>132</td>
                <td>14</td>
                <td>16</td>
                <td>50</td>
                <td>21</td>
                <td>24</td>
                <td>1.8</td>
                <td>40°</td>
                <td>95</td>
                <td>104011</td>
            </tr>
            <tr>
                <td>NP-150</td>
                <td>157</td>
                <td>15</td>
                <td>17</td>
                <td>50</td>
                <td>23</td>
                <td>25</td>
                <td>1.8</td>
                <td>40°</td>
                <td>135</td>
                <td>104028</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <td>Cutting capacity (φmm)</td>
                <td>Stainless</td>
                <td>Soft iron</td>
                <td>Copper</td>
                <td>Stranded copper wire [mm²]</td>
                <td>Brass</td>
                <td>Plastic</td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>NP-125</td>
                <td>0.9</td>
                <td>1.2</td>
                <td>2.0</td>
                <td>2.0</td>
                <td>1.6</td>
                <td>3.0</td>
            </tr>
            <tr>
                <td>NP-150</td>
                <td>1.2</td>
                <td>1.6</td>
                <td>2.6</td>
                <td>5.5</td>
                <td>2.0</td>
                <td>4.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}