import terminalCrimpingToolImage from "../../../static/images/tools/wireCutters/431-0014.jpg";

const terminalCrimpingTool = {
    name: 'Terminal Crimping Tool ',
    image: terminalCrimpingToolImage,
    images: [terminalCrimpingToolImage],
    modelNos: ['431-0014'],
    company: 'AutoMaster',
    madeIn: 'Taiwan',
    description: [

    ],
    specifications: [
		"Non insulated terminal range: 1.25, 2, 3.5, 5.5, 8, 14mm2.",
		"Copper tube terminal range: 1.5, 2.5, 4, 6, 10, 16mm2.",
		"Length: 298mm.",
		"Weight: 0.52kgs.",
    ],
    detail: () => null,
    path: '/tools/wireCutters/terminalCrimpingTool',
    id: 'terminalCrimpingTool'
};

export default terminalCrimpingTool;