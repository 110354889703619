import React from "react";
import {Table} from "react-bootstrap";

export default function EndCuttingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="6">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Edge width</th>
                <th>Maximum jaw opening</th>
                <th>Head width</th>
                <th>Jaw depth</th>
                <th>Handle width</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>EN-150</td>
                <td>166</td>
                <td>22</td>
                <td>14.5</td>
                <td>35</td>
                <td>10</td>
                <td>52</td>
                <td>205</td>
                <td>102017</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Stainless</th>
                <th>Soft iron</th>
                <th>Copper</th>
                <th>Brass</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>EN-150</td>
                <td>1.6</td>
                <td>2.6</td>
                <td>3.2</td>
                <td>2.6</td>
            </tr>
            </tbody>
        </Table>
    </>
}