import React from "react";

export default function FlatScrewDrivers() {
    return <div className='tableFixHead'>
        <table className="table table-dark table-striped table-bordered table-hover">
            <thead>
            <tr>
                <th rowSpan='2'>Product No</th>
                <th colSpan='2'>TIP SIZE</th>
                <th rowSpan='2'>A</th>
                <th rowSpan='2'>C</th>
                <th rowSpan='2'>D</th>
                <th rowSpan='2'>L</th>
                <th rowSpan='2'>Weight <br/> (g)</th>
            </tr>
            <tr>
                <th>B</th>
                <th>T</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>932</td>
                <td>0.6</td>
                <td>0.4</td>
                <td>50</td>
                <td>13</td>
                <td>3.0</td>
                <td>110</td>
                <td>10</td>
            </tr>
            <tr>
                <td>933</td>
                <td>1.6</td>
                <td>0.4</td>
                <td>75</td>
                <td>13</td>
                <td>3.0</td>
                <td>135</td>
                <td>12</td>
            </tr>
            <tr>
                <td>911</td>
                <td>3.0</td>
                <td>0.4</td>
                <td>50</td>
                <td>13</td>
                <td>3.0</td>
                <td>110</td>
                <td>10</td>
            </tr>
            <tr>
                <td>901</td>
                <td>3.0</td>
                <td>0.5</td>
                <td>75</td>
                <td>17</td>
                <td>3.0</td>
                <td>150</td>
                <td>21</td>
            </tr>
            <tr>
                <td>902</td>
                <td>3.0</td>
                <td>0.4</td>
                <td>100</td>
                <td>17</td>
                <td>3.0</td>
                <td>175</td>
                <td>22</td>
            </tr>
            <tr>
                <td>912</td>
                <td>3.5</td>
                <td>0.5</td>
                <td>75</td>
                <td>14</td>
                <td>3.5</td>
                <td>140</td>
                <td>16</td>
            </tr>
            <tr>
                <td>842</td>
                <td>3.5</td>
                <td>0.5</td>
                <td>150</td>
                <td>17</td>
                <td>3.5</td>
                <td>225</td>
                <td>35</td>
            </tr>
            <tr>
                <td>913</td>
                <td>4.0</td>
                <td>0.6</td>
                <td>100</td>
                <td>18</td>
                <td>4.0</td>
                <td>18</td>
                <td>30</td>
            </tr>
            <tr>
                <td>712</td>
                <td>4.0</td>
                <td>0.6</td>
                <td>125</td>
                <td>17</td>
                <td>4.0</td>
                <td>200</td>
                <td>30</td>
            </tr>
            <tr>
                <td>833</td>
                <td>4.0</td>
                <td>0.6</td>
                <td>200</td>
                <td>18</td>
                <td>4.0</td>
                <td>280</td>
                <td>40</td>
            </tr>
            <tr>
                <td>713</td>
                <td>5.0</td>
                <td>0.6</td>
                <td>75</td>
                <td>18</td>
                <td>5.0</td>
                <td>155</td>
                <td>35</td>
            </tr>
            <tr>
                <td>936</td>
                <td>5.0</td>
                <td>0.8</td>
                <td>150</td>
                <td>22</td>
                <td>5.0</td>
                <td>236</td>
                <td>60</td>
            </tr>
            <tr>
                <td>921</td>
                <td>6.0</td>
                <td>0.6</td>
                <td>75</td>
                <td>22</td>
                <td>6.0</td>
                <td>161</td>
                <td>55</td>
            </tr>
            <tr>
                <td>922</td>
                <td>6.0</td>
                <td>6.0</td>
                <td>100</td>
                <td>22</td>
                <td>6.0</td>
                <td>186</td>
                <td>60</td>
            </tr>
            <tr>
                <td>923</td>
                <td>6.0</td>
                <td>0.8</td>
                <td>125</td>
                <td>22</td>
                <td>6.0</td>
                <td>211</td>
                <td>62</td>
            </tr>
            <tr>
                <td>824</td>
                <td>6.0</td>
                <td>0.8</td>
                <td>150</td>
                <td>22</td>
                <td>6.0</td>
                <td>236</td>
                <td>65</td>
            </tr>
            <tr>
                <td>825</td>
                <td>6.0</td>
                <td>0.8</td>
                <td>200</td>
                <td>22</td>
                <td>6.0</td>
                <td>286</td>
                <td>77</td>
            </tr>
            <tr>
                <td>826</td>
                <td>6.0</td>
                <td>0.8</td>
                <td>250</td>
                <td>22</td>
                <td>6.0</td>
                <td>336</td>
                <td>90</td>
            </tr>
            <tr>
                <td>924</td>
                <td>8.0</td>
                <td>1.2</td>
                <td>125</td>
                <td>28</td>
                <td>8.0</td>
                <td>230</td>
                <td>115</td>
            </tr>
            <tr>
                <td>925</td>
                <td>8.0</td>
                <td>1.2</td>
                <td>150</td>
                <td>28</td>
                <td>8.0</td>
                <td>255</td>
                <td>130</td>
            </tr>
            <tr>
                <td>926</td>
                <td>8.0</td>
                <td>1.2</td>
                <td>175</td>
                <td>28</td>
                <td>8.0</td>
                <td>280</td>
                <td>130</td>
            </tr>
            <tr>
                <td>827</td>
                <td>8.0</td>
                <td>1.2</td>
                <td>200</td>
                <td>28</td>
                <td>8.0</td>
                <td>305</td>
                <td>145</td>
            </tr>
            <tr>
                <td>828</td>
                <td>8.0</td>
                <td>1.2</td>
                <td>250</td>
                <td>28</td>
                <td>8.0</td>
                <td>355</td>
                <td>165</td>
            </tr>
            <tr>
                <td>829</td>
                <td>8.0</td>
                <td>1.2</td>
                <td>300</td>
                <td>28</td>
                <td>8.0</td>
                <td>405</td>
                <td>180</td>
            </tr>
            <tr>
                <td>725</td>
                <td>10.0</td>
                <td>1.2</td>
                <td>150</td>
                <td>26</td>
                <td>8.0</td>
                <td>248</td>
                <td>120</td>
            </tr>
            <tr>
                <td>927</td>
                <td>10.0</td>
                <td>1.2</td>
                <td>200</td>
                <td>34</td>
                <td>10.0</td>
                <td>327</td>
                <td>245</td>
            </tr>
            <tr>
                <td>928</td>
                <td>10.0</td>
                <td>1.2</td>
                <td>250</td>
                <td>34</td>
                <td>10.0</td>
                <td>377</td>
                <td>275</td>
            </tr>
            <tr>
                <td>929</td>
                <td>10.0</td>
                <td>1.5</td>
                <td>300</td>
                <td>34</td>
                <td>10.0</td>
                <td>427</td>
                <td>310</td>
            </tr>
            <tr>
                <td>930</td>
                <td>10.0</td>
                <td>1.5</td>
                <td>400</td>
                <td>34</td>
                <td>10.0</td>
                <td>527</td>
                <td>365</td>
            </tr>
            <tr>
                <td>931</td>
                <td>13.0</td>
                <td>1.9</td>
                <td>450</td>
                <td>34</td>
                <td>10.0</td>
                <td>577</td>
                <td>395</td>
            </tr>
            </tbody>
        </table>
    </div>
}
