import multiPurposeLongNosePlierImage from "../../../static/images/tools/pliers/krp150.jpg";
import multiPurposeLongNosePlierMeasurements from "../../../static/images/tools/pliers/krp150Measurements.png";
import MultiPurposeLongNosePliers
    from "../../../components/ToolDetails/Pliers/MultiPurposeLongNosePliers/MultiPurposeLongNosePliers";

const multiPurposeLongNosePlier = {
    name: 'Multi-Purpose Long Nose Pliers',
    image: multiPurposeLongNosePlierImage,
    images: [multiPurposeLongNosePlierImage, multiPurposeLongNosePlierMeasurements],
    modelNos: ["KRP-150"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Long nose pliers with multi functions.",
        "This is not an exclusive crimping tool.",
    ],
    specifications: [
        "Material | Carbon steel for machinery（S58C)",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 54～62",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: MultiPurposeLongNosePliers,
    path: '/tools/pliers/multiPurposeLongNosePliers',
    id: 'multiPurposeLongNosePliers'
};
export default multiPurposeLongNosePlier;