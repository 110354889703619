import React from "react";
import {Table} from "react-bootstrap";

export default function CableCutterWithStripper() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="4">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CS-22</td>
                <td>163</td>
                <td>25</td>
                <td>10</td>
                <td>43</td>
                <td>175</td>
                <td>110203</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>Copper core cable</th>
                <th>IV Cable</th>
                <th>Fulcrum side edge</th>
                <th>Tip edge</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CS-22</td>
                <td>9.0</td>
                <td>22</td>
                <td>4～16</td>
                <td>0.15～6</td>
            </tr>
            </tbody>
        </Table>
    </>
}