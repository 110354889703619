import plasticCuttingPliersImage from "../../../static/images/tools/miniTools/pn115.jpg";
import plasticCuttingPliersMeasurements from "../../../static/images/tools/miniTools/pn115.png";
import PlasticCuttingPliers from "../../../components/ToolDetails/MiniTools/PlasticCuttingPliers/PlasticCuttingPliers";

const plasticCuttingPliers = {
    name: 'Plastic Cutting Pliers',
    image: plasticCuttingPliersImage,
    images: [plasticCuttingPliersImage, plasticCuttingPliersMeasurements],
    description: [
		"For cutting plastic molding gates or nylon thread.",
    ],
    modelNos: ["PN-100", "PN-115"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
		"Material | Carbon steel for machinery",
		"Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
		"Body hardness [HRC] | 42～50",
		"Edge hardness [HRC] | 54～62",
		"Spring | Coil spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: PlasticCuttingPliers,
    path: '/tools/miniTools/plasticCuttingPliers',
    id: 'plasticCuttingPliers'
};

export default plasticCuttingPliers;