import React from "react";
import {Table} from "react-bootstrap";

export default function MultiPurposeLongNosePliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="7">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Edge length</th>
                <th>Hole diameter</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>KRP-150</td>
                <td>161</td>
                <td>17</td>
                <td>10</td>
                <td>52</td>
                <td>50</td>
                <td>10</td>
                <td>1.5</td>
                <td>125</td>
                <td>103069</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <td>Cutting capacity (φmm)</td>
                <td>Stainless</td>
                <td>Soft iron</td>
                <td>Copper</td>
                <td>Brass</td>
                <td>Stranded copper wire [mm²]</td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>KRP-150</td>
                <td>1.6</td>
                <td>2.0</td>
                <td>2.6</td>
                <td>2.0</td>
                <td>5.5㎟</td>
            </tr>
            </tbody>
        </Table>
    </>
}