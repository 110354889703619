import React from "react";
import {Table} from "react-bootstrap";

export default function SnapRingPliersStraightJawExternalRings() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="5">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">JAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Handle width</th>
                <th>Head thickness</th>
                <th>Nose length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SES-125P</td>
                <td>145</td>
                <td>18</td>
                <td>71</td>
                <td>8</td>
                <td>29</td>
                <td>110</td>
                <td>110081</td>
            </tr>
            <tr>
                <td>SES-175P</td>
                <td>177</td>
                <td>22</td>
                <td>85</td>
                <td>10</td>
                <td>34</td>
                <td>170</td>
                <td>110128</td>
            </tr>
            <tr>
                <td>SES-230P</td>
                <td>243</td>
                <td>24</td>
                <td>111</td>
                <td>11</td>
                <td>42</td>
                <td>285</td>
                <td>110166</td>
            </tr>
            <tr>
                <td>SES-300P</td>
                <td>326</td>
                <td>29</td>
                <td>145</td>
                <td>12</td>
                <td>55</td>
                <td>535</td>
                <td>113525</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <td/>
                <td>Sizes across flats : (φmm)</td>
                <td>Usable range（mm)</td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SES-125P</td>
                <td>1.4</td>
                <td>12～25</td>
            </tr>
            <tr>
                <td>SES-175P</td>
                <td>1.8</td>
                <td>19～40</td>
            </tr>
            <tr>
                <td>SES-230P</td>
                <td>2.3</td>
                <td>32～75</td>
            </tr>
            <tr>
                <td>SES-300P</td>
                <td>3.0</td>
                <td>75～140</td>
            </tr>
            </tbody>
        </Table>
    </>
}