import greaseNipplesInchSize110pcsImage from "../../../static/images/tools/greaseNipples/GE-243.jpg";
import GreaseNipplesMetricSize110pcs
    from "../../../components/ToolDetails/GreaseNipples/GreaseNipplesMetricSize110pcs/GreaseNipplesMetricSize110pcs";

const greaseNipplesInchSize110pcs = {
    name: '110pcs Grease Nipples (Inch Size)',
    image: greaseNipplesInchSize110pcsImage,
    images: [greaseNipplesInchSize110pcsImage],
    modelNos: ["GE-243"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: ["Kit contains 6 popular & useful inch sizes."],
    specifications: [],
    detail: GreaseNipplesMetricSize110pcs,
    path: '/tools/greaseNipples/greaseNipplesInchSize110pcs',
    id: 'greaseNipplesInchSize110pcs'
}

export default greaseNipplesInchSize110pcs;