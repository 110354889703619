import slimPlasticCutterFlatBladeImage from "../../../static/images/tools/wireCutters/snp145f.jpg";
import slimPlasticCutterFlatBladeMeasurement from "../../../static/images/tools/wireCutters/snp145fMeasurements.png";
import SlimPlasticCutterFlatBlade
    from "../../../components/ToolDetails/WireCutters/SlimPlasticCutterFlatBlade/SlimPlasticCutterFlatBlade";

const slimPlasticCutterFlatBlade = {
    name: 'Slim Plastic Cutter Flat-blade',
    image: slimPlasticCutterFlatBladeImage,
    images: [slimPlasticCutterFlatBladeImage, slimPlasticCutterFlatBladeMeasurement],
    modelNos: ["SNP-145F", "SNP-165F"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Slim head reaches target inside narrow space.",
        "Flat blade is suitable for cutting targets on the curved surface.",
        "Long-life torsion spring loaded.",
    ],
    specifications: [
        "Material | Alloy steel",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Edge hardness [HRC] | 58～64",
        "Spring | Torsion spring",
        "Handle cover | PVC dipping",
    ],
    detail: SlimPlasticCutterFlatBlade,
    path: '/tools/wireCutters/slimPlasticCutterFlatBlade',
    id: 'slimPlasticCutterFlatBlade'
};

export default slimPlasticCutterFlatBlade;