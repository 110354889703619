import React from "react";
import {Table} from "react-bootstrap";

export default function GreaseNipplesInchSize95pcs() {
    return <>
        <h4>Types of Metrics in kit</h4>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th className='center'>Angle of Hose</th>
                <th colSpan='2'>0° (Straight)</th>
                <th colSpan='2'>45°</th>
                <th colSpan='2'>90°</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>Size</th>
                <td><sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>8</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>8</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>4</sub>”</td>
                <td><sup>1</sup>&frasl;<sub>8</sub>”</td>
            </tr>
            <tr>
                <th>Thread Type</th>
                <td>28</td>
                <td>NPT</td>
                <td>28</td>
                <td>NPT</td>
                <td>28</td>
                <td>NPT</td>
            </tr>
            </tbody>
        </Table>
    </>
}