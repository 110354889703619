import React, {Component} from "react";
import {categories, categoriesToTools} from "../../constants/tools";
import {Col, ListGroup, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import searchFields from "../../constants/search";

export default class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchResult: []
        }
        this.timeout = 0;

        this.doSearch = this.doSearch.bind(this);
    }

    keyWordInField(keyWord, field, tool) {
        const stringField = (typeof field === 'string' || field instanceof String) && field.toLowerCase().includes(keyWord)
        const arrayField = Array.isArray(field) && field.some(f => f.toLowerCase().includes(keyWord))
        if (tool.id === 'slipJointPliers')
            debugger;
        return stringField || arrayField
    }

    searchTools(searchText) {
        if (searchText === '') {
            this.setState({searchResult: []})
            return
        }
        const keyWords = searchText.toLowerCase().split(' ').filter(s => s !== '');
        const allTools = categories.concat(Object.values(categoriesToTools).flat())
        let found = 0;
        let index = 0
        let searchResult = []
        while (found < 10 && index < allTools.length) {
            const tool = allTools[index];

            if (keyWords.some(k => searchFields.some(sf => tool[sf] && this.keyWordInField(k, tool[sf], tool)))) {
                searchResult = searchResult.concat(tool)
                found++;
            }

            index++;
        }
        this.setState({searchResult: searchResult})
    }

    doSearch(e) {
        const searchText = e.target.value;
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.searchTools(searchText);
        }, 400);
    }

    render() {
        return <div className='search'>
            <input
                type='text'
                placeholder='Search...'
                className="col-md-6 col-sm-9 search__box"
                onChange={this.doSearch}
            />
            {
                <ListGroup bsPrefix='col-md-6 col-sm-9 search__result list-group'>
                    {
                        this.state.searchResult.map(sr => <ListGroup.Item
                            as={Link}
                            to={sr.path}
                            variant="dark"
                            bsPrefix='search__resultItem list-group-item'
                        >
                            <Row>
                                <Col xs={4}>
                                    <img src={sr.image} className='w-100' alt={sr.name}/>
                                </Col>
                                <Col xs={8} bsPrefix='search__resultItemName col'>
                                    {sr.name}
                                </Col>
                            </Row>
                        </ListGroup.Item>)
                    }
                </ListGroup>
            }
        </div>;
    }
}