import snapRingPliersForSmallExternalRingsImage from "../../../static/images/tools/pliers/mse125.jpg";
import snapRingPliersForSmallExternalRingsMeasurements
    from "../../../static/images/tools/pliers/mse125Measurements.png";
import SnapRingPliersForSmallExternalRings
    from "../../../components/ToolDetails/Pliers/SnapRingPliersForSmallExternalRings/SnapRingPliersForSmallExternalRings";

const snapRingPliersForSmallExternalRings = {
    name: 'Snap Ring Pliers for Small External Rings',
    image: snapRingPliersForSmallExternalRingsImage,
    images: [snapRingPliersForSmallExternalRingsImage, snapRingPliersForSmallExternalRingsMeasurements],
    modelNos: ["MSE-125"],
    company: "King TTC",
    madeIn: 'Japan',
    description: ["For the smallest external snap rings."],
    specifications: [
        "Material | Carbon steel for machinery",
        "Heat Treatment | Oxide-free heat treatment",
        "Hardness | 42～46",
        "Spring | Coil spring",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: SnapRingPliersForSmallExternalRings,
    path: '/tools/pliers/snapRingPliersForSmallExternalRings',
    id: 'snapRingPliersForSmallExternalRings'
};
export default snapRingPliersForSmallExternalRings;