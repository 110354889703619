import lightWeightAluminiumHandleCableCutterImage from "../../../static/images/tools/wireCutters/ca800al.jpg";
import lightWeightAluminiumHandleCableCutterMeasurement
    from "../../../static/images/tools/wireCutters/ca800alMeasurements.png";
import LightWeightAluminiumHandleCableCutter
    from "../../../components/ToolDetails/WireCutters/LightWeightAluminiumHandleCableCutter/LightWeightAluminiumHandleCableCutter";

const lightWeightAluminiumHandleCableCutter = {
    name: 'Light Weight Aluminium Handle Cable Cutter',
    image: lightWeightAluminiumHandleCableCutterImage,
    images: [lightWeightAluminiumHandleCableCutterImage, lightWeightAluminiumHandleCableCutterMeasurement],
    modelNos: ["CA-450AL", "CA-600AL", "CA-800AL"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Aluminium handles realized weight reduction.",
        "High mobility helps electric arranging works.",
    ],
    specifications: [
        "Surface treatment (Head) | Phosphate conversion coating",
        "Handle | Aluminium",
    ],
    detail: LightWeightAluminiumHandleCableCutter,
    path: '/tools/wireCutters/lightWeightAluminiumHandleCableCutter',
    id: 'lightWeightAluminiumHandleCableCutter'
};

export default lightWeightAluminiumHandleCableCutter;