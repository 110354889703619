import image from '../../../static/images/tools/socketAccessories/quickReleaseReversibleRatchetHandleKnurledGrip.jpg'
import QuickReleaseReversibleRatchetHandleKnurledGrip
    from "../../../components/ToolDetails/SocketAccessories/QuickReleaseReversibleRatchetHandleKnurledGrip/QuickReleaseReversibleRatchetHandleKnurledGrip";

const quickReleaseReversibleRatchetHandleKnurledGrip = {
    name: 'Quick Release Reversible Ratchet Handle Knurled Grip',
    image: image,
    images: [image],
    modelNos: ['361-1002', '361-1003', '361-1004'],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [],
    detail: QuickReleaseReversibleRatchetHandleKnurledGrip,
    path: '/tools/socketAccessories/quickReleaseReversibleRatchetHandleKnurledGrip',
    id: 'quickReleaseReversibleRatchetHandleKnurledGrip'
}

export default quickReleaseReversibleRatchetHandleKnurledGrip