import {Nav, Navbar} from "react-bootstrap";
import {Link, useLocation} from 'react-router-dom'

export default function NavBar() {
    const location = useLocation();

    return (
        <Navbar className='tiles' variant="dark" expand="md" sticky="top" collapseOnSelect={true}>
            <Navbar.Brand as={Link} to="/">Hakimi Est</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/" href='/' active={location.pathname === '/'}>
                        Home
                    </Nav.Link>
                    <Nav.Link as={Link} to="/tools" href='tools' active={location.pathname.startsWith('/tools')}>
                        Tools
                    </Nav.Link>
                    <Nav.Link as={Link} to="/contactus" href='/contactus' active={location.pathname === '/contactus'}>
                        Contact Us
                    </Nav.Link>
                    <Nav.Link as={Link} to="/search" href='/search' active={location.pathname === '/search'}>
                        Search
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
            <Navbar.Brand as={Link} to="/" bsPrefix="navbar-brand Kanz-al-Marjaan NavBar__brand--arabic">
                مؤسسة الحکيمي
            </Navbar.Brand>
        </Navbar>
    );
}