import React from "react";
import {Table} from "react-bootstrap";

export default function PipeTapsBritishStandards() {
    return <Table striped bordered hover variant="dark" responsive>
        <thead>
        <tr>
            <th rowSpan='2'> Nominal Diameter<br/> (INCH)</th>
            <th rowSpan='2'>Thread Per Inch</th>
            <th rowSpan='2'>Basic Pitch Diameter</th>
            <th colSpan="4">Regular</th>
            <th rowSpan="2">Weight <br/> Per Pc<br/>(g)</th>
        </tr>
        <tr>
            <th>A (mm)</th>
            <th>B (mm)</th>
            <th>B1 (mm)</th>
            <th>C (mm)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td><sup>1</sup>&frasl;<sub>16</sub></td>
            <td>28</td>
            <td>7.753</td>
            <td>55</td>
            <td>17.5</td>
            <td>12</td>
            <td>8</td>
            <td>15</td>
        </tr>
        <tr>
            <td><sup>1</sup>&frasl;<sub>8</sub></td>
            <td>28</td>
            <td>9.728</td>
            <td>55</td>
            <td>19</td>
            <td>13</td>
            <td>8</td>
            <td>20</td>
        </tr>
        <tr>
            <td><sup>1</sup>&frasl;<sub>4</sub></td>
            <td>19</td>
            <td>13.157</td>
            <td>62</td>
            <td>28</td>
            <td>21</td>
            <td>11</td>
            <td>40</td>
        </tr>
        <tr>
            <td><sup>3</sup>&frasl;<sub>8</sub></td>
            <td>19</td>
            <td>16.662</td>
            <td>65</td>
            <td>28</td>
            <td>21</td>
            <td>14</td>
            <td>80</td>
        </tr>
        <tr>
            <td><sup>1</sup>&frasl;<sub>2</sub></td>
            <td>14</td>
            <td>20.955</td>
            <td>80</td>
            <td>35</td>
            <td>25</td>
            <td>18</td>
            <td>140</td>
        </tr>
        <tr>
            <td><sup>5</sup>&frasl;<sub>8</sub></td>
            <td>14</td>
            <td>22.912</td>
            <td>85</td>
            <td>35</td>
            <td>25</td>
            <td>20</td>
            <td>160</td>
        </tr>
        <tr>
            <td><sup>3</sup>&frasl;<sub>4</sub></td>
            <td>14</td>
            <td>26.441</td>
            <td>85</td>
            <td>35</td>
            <td>25</td>
            <td>23</td>
            <td>240</td>
        </tr>
        <tr>
            <td><sup>7</sup>&frasl;<sub>8</sub></td>
            <td>14</td>
            <td>30.202</td>
            <td>90</td>
            <td>40</td>
            <td>28</td>
            <td>24</td>
            <td>340</td>
        </tr>
        <tr>
            <td>1</td>
            <td>11</td>
            <td>33.249</td>
            <td>95</td>
            <td>45</td>
            <td>32</td>
            <td>26</td>
            <td>420</td>
        </tr>
        <tr>
            <td>1 <sup>1</sup>&frasl;<sub>8</sub></td>
            <td>11</td>
            <td>37.898</td>
            <td>105</td>
            <td>45</td>
            <td>32</td>
            <td>30</td>
            <td>580</td>
        </tr>
        <tr>
            <td>1 <sup>1</sup>&frasl;<sub>4</sub></td>
            <td>11</td>
            <td>41.910</td>
            <td>105</td>
            <td>45</td>
            <td>32</td>
            <td>32</td>
            <td>660</td>
        </tr>
        <tr>
            <td>1 <sup>3</sup>&frasl;<sub>8</sub></td>
            <td>11</td>
            <td>44.325</td>
            <td>110</td>
            <td>45</td>
            <td>32</td>
            <td>35</td>
            <td>770</td>
        </tr>
        <tr>
            <td>1 <sup>1</sup>&frasl;<sub>2</sub></td>
            <td>11</td>
            <td>47.803</td>
            <td>110</td>
            <td>45</td>
            <td>32</td>
            <td>38</td>
            <td>890</td>
        </tr>
        </tbody>
    </Table>
}
