import bentNosePlierImage from "../../../static/images/tools/pliers/fr150.jpg";
import bentNosePlierMeasurements from "../../../static/images/tools/pliers/fr150Measurements.png";
import BentNosePliers from "../../../components/ToolDetails/Pliers/BentNosePliers/BentNosePliers";

const bentNosePlier = {
    name: 'Bent Nose Pliers',
    image: bentNosePlierImage,
    images: [bentNosePlierImage, bentNosePlierMeasurements],
    modelNos: ["FR-150"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Nickel chrome plated surface.",
        "For gardening, fishing and others."
    ],
    specifications: [
        "Material | Carbon steel for machinery（S55C）",
        "Heat Treatment | Oxide-free heat treatment",
        "Body hardness [HRC] | 47～55",
        "Handle cover | PVC dipping",
        "Surface treatment | Nickel-chrome plating",
    ],
    detail: BentNosePliers,
    path: '/tools/pliers/bentNosePliers',
    id: 'bentNosePliers'
};
export default bentNosePlier;