import terminalCrimpingToolBiggerImage from "../../../static/images/tools/wireCutters/431-0038.jpg";

const terminalCrimpingToolBigger = {
    name: 'Terminal Crimping Tool ',
    image: terminalCrimpingToolBiggerImage,
    images: [terminalCrimpingToolBiggerImage],
    modelNos: ['431-0038'],
    company: 'AutoMaster',
    madeIn: 'Taiwan',
    description: [

    ],
    specifications: [
		"Non insulated terminal range: 5.5, 8, 14, 22, 38mm2.",
		"Copper tube terminal range: 6, 10, 16, 25, 35, 50mm2.",
		"Length: 375mm.",
		"Weight: 0.88kgs.",
    ],
    detail: () => null,
    path: '/tools/wireCutters/terminalCrimpingToolBigger',
    id: 'terminalCrimpingToolBigger'
};

export default terminalCrimpingToolBigger;