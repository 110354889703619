import React from "react";
import {Table} from "react-bootstrap";

export default function ShearingPliers() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th colSpan="7">Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
                <th>Head width</th>
                <th>Head thickness</th>
                <th>Handle width</th>
                <th>Nose length</th>
                <th>Jaw side edge length</th>
                <th>Handle side edge length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>SP-200</td>
                <td>209</td>
                <td>27</td>
                <td>10</td>
                <td>52</td>
                <td>50</td>
                <td>22</td>
                <td>16</td>
                <td>285</td>
                <td>106121</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity (φmm)</th>
                <th>VVF cable</th>
                <th>Iron sheet (thickness)</th>
                <th>Copper sheet (thickness)</th>
                <th>Cutting capacity (φmm)</th>
                <th>Soft iron</th>
                <th>Copper</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Jaw side edge</td>
                <td>2.0×2cores</td>
                <td>0.6</td>
                <td>1.0</td>
                <td>Handle side edge</td>
                <td>2.0</td>
                <td>2.0</td>
            </tr>
            </tbody>
        </Table>
    </>
}