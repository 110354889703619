import valveSpringCompressorBigImage from "../../../static/images/tools/valveSpringCompressor/123-1205.jpg";

const valveSpringCompressorBig = {
    name: 'Valve Spring Compressor',
    image: valveSpringCompressorBigImage,
    images: [valveSpringCompressorBigImage],
    modelNos: ["123-1205"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [
        'For large engines and diesel engines.',
        'Unique "Direct Action" compressor lever gives improved visibility and easy of use in areas of difficult access.',
        'Collet release adapters: restricted access adapter, 25mm and 30mm.',
        'Jaw Opening (A): Min. 35mm, Max. 200mm.',
        'Throat (B): Clearance 250mm.',
    ],
    detail: () => null,
    path: '/tools/valveSpringCompressor/valveSpringCompressorBig',
    id: 'valveSpringCompressorBig'
};

export default valveSpringCompressorBig;