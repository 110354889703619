import sideCuttingPlierImage from '../../../static/images/tools/pliers/tp150.jpg'
import sideCuttingPlierMeasurements from '../../../static/images/tools/pliers/tp150Measurements.png'
import SideCuttingPlierEurope from "../../../components/ToolDetails/Pliers/SideCuttingPlierEurope/SideCuttingPlierEurope";

const sideCuttingPlierEurope = {
    name: 'Side Cutting Pliers Europe-type',
    image: sideCuttingPlierImage,
    images: [sideCuttingPlierImage, sideCuttingPlierMeasurements],
    modelNos: ["TP-150", "TP-175", "TP-200"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
        "Material | Carbon steel for machinery（S58C)",
        "Heat Treatment | Oxide-free heat treatment",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils",
    ],
    detail: SideCuttingPlierEurope,
    description: [
        "For cutting wire, bending metal sheets.",
        "Oval serration part is comfortable for holding hex nuts.",
    ],
    path: '/tools/pliers/sideCuttingPlierEuropeType',
    id: 'sideCuttingPlierEuropeType',
}

export default sideCuttingPlierEurope;
