import cableCutterImage from "../../../static/images/tools/wireCutters/ca22.jpg";
import cableCutterMeasurements from "../../../static/images/tools/wireCutters/ca22Measurements.png";
import CableCutterSmall from "../../../components/ToolDetails/WireCutters/CableCutterSmall/CableCutterSmall";

const cableCutterSmall = {
    name: 'Cable Cutter',
    image: cableCutterImage,
    images: [cableCutterImage, cableCutterMeasurements],
    modelNos: ["CA-22", "CA-38", "CA-60"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "Cutting cables with ease.",
        "Clean cut surface without deformation.",
        "Double edge type.",
        "Tip edge is suitable for cutting cables inside of narrow space like a switchboard box.",
        "Exclusive use for cutting copper core cables.",
    ],
    specifications: [
        "Material | Alloy steel",
        "Heat Treatment | Oxide-free heat treatment",
        "Hardness | 54～62",
        "Handle cover | PVC dipping",
    ],
    detail: CableCutterSmall,
    path: '/tools/wireCutters/cableCutterSmall',
    id: 'cableCutterSmall'
};

export default cableCutterSmall;