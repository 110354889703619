import React from "react";
import {Table} from "react-bootstrap";

export default function CableCutter() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th rowSpan="2"/>
                <th>Size[mm]</th>
                <th rowSpan="2">Weight<br/>[g]</th>
                <th rowSpan="2">EAN-code<br/>(4952269)</th>
            </tr>
            <tr>
                <th>Overall length</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CA-450</td>
                <td>450</td>
                <td>1,340</td>
                <td>110692</td>
            </tr>
            <tr>
                <td>CA-650</td>
                <td>680</td>
                <td>2,480</td>
                <td>110708</td>
            </tr>
            <tr>
                <td>CA-950</td>
                <td>960</td>
                <td>4,615</td>
                <td>110715</td>
            </tr>
            </tbody>
        </Table>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>Cutting capacity</th>
                <th>IV Cable (mmsq)</th>
                <th>Communication cable (mmsq/φmm)</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>CA-450</td>
                <td>150</td>
                <td>-</td>
            </tr>
            <tr>
                <td>CA-650</td>
                <td>325</td>
                <td>-</td>
            </tr>
            <tr>
                <td>CA-950</td>
                <td>325</td>
                <td>500/35</td>
            </tr>
            </tbody>
        </Table>
    </>
}