import snapRingPliersStraightJawExternalRingsImage from "../../../static/images/tools/pliers/ses.jpg";
import snapRingPliersStraightJawExternalRingsMeasurements from "../../../static/images/tools/pliers/sesMeasurements.png";
import SnapRingPliersStraightJawExternalRings
    from "../../../components/ToolDetails/Pliers/SnapRingPliersStraightJawExternalRings/SnapRingPliersStraightJawExternalRings";

const snapRingPliersStraightJawExternalRings = {
    name: 'Snap Ring Pliers Straight-Jaw for External Rings',
    image: snapRingPliersStraightJawExternalRingsImage,
    images: [snapRingPliersStraightJawExternalRingsImage, snapRingPliersStraightJawExternalRingsMeasurements],
    modelNos: ["SES-125P", "SES-175P", "SES-230P", "SES-300P"],
    company: "King TTC",
    madeIn: 'Japan',
    description: [
        "For external snap rings (circlip rings).",
        "High durability and wear resistance are realized by using piano wire for bits.",
        "Tapered bit-basement and non-slip processed bit-surface prevent dropping rings out.",
        "Holding stability improve safety and efficiency of works.",
    ],
    specifications: [
        "Material(Body) | Alloy steel",
        "Material (Bit) | Piano wire",
        "Heat Treatment | Oxide-free heat treatment",
        "Spring | Coil spring",
        "Handle cover | PVC dipping",
    ],
    detail: SnapRingPliersStraightJawExternalRings,
    path: '/tools/pliers/snapRingPliersStraightJawExternalRings',
    id: 'snapRingPliersStraightJawExternalRings'
};
export default snapRingPliersStraightJawExternalRings;