import endCuttingPliers from "./miniTools/endCuttingPliers";
import miniCutter from "./miniTools/miniCutter";
import cuttingPliers from "./miniTools/cuttingPliers";
import microCuttingPliers from "./miniTools/microCuttingPliers";
import powerMetalCutter from "./miniTools/powerMetalCutter";
import longNosePliers from "./miniTools/longNosePliers";
import microLongNosePliers from "./miniTools/microLongNosePliers";
import bentNosePliers from "./miniTools/bentNosePliers";
import multiPurposeLongNosePliers from "./miniTools/multiPurposeLongNosePliers";
import plasticCuttingPliers from "./miniTools/plasticCuttingPliers";

const miniTools = [
    endCuttingPliers,
    miniCutter,
    cuttingPliers,
    microCuttingPliers,
    powerMetalCutter,
    longNosePliers,
    microLongNosePliers,
    bentNosePliers,
    multiPurposeLongNosePliers,
    plasticCuttingPliers
]

export default miniTools