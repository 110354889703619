import longNosePliersImage from "../../../static/images/tools/miniTools/mr115.jpg";
import longNosePliersMeasurements from "../../../static/images/tools/miniTools/mr115.png";
import LongNosePliers from "../../../components/ToolDetails/MiniTools/LongNosePliers/LongNosePliers";

const longNosePliers = {
    name: 'Long Nose Pliers',
    image: longNosePliersImage,
    images: [longNosePliersImage, longNosePliersMeasurements],
    description: [
		"For fine works and cutting wire.",
    ],
    modelNos: ["MR-100", "MR-115", "MR-120"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
		"Material | Carbon steel for machinery",
		"Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
		"Body hardness [HRC] | 42～50",
		"Edge hardness [HRC] | 54～62",
		"Spring | Coil spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: LongNosePliers,
    path: '/tools/miniTools/longNosePliers',
    id: 'longNosePliers'
};

export default longNosePliers;