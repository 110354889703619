import microLongNosePliersImage from "../../../static/images/tools/miniTools/er125.jpg";
import microLongNosePliersMeasurements from "../../../static/images/tools/miniTools/er125.png";
import MicroLongNosePliers from "../../../components/ToolDetails/MiniTools/MicroLongNosePliers/MicroLongNosePliers";

const microLongNosePliers = {
    name: 'Micro Long Nose Pliers',
    image: microLongNosePliersImage,
    images: [microLongNosePliersImage, microLongNosePliersMeasurements],
    description: [
		"Slender and long jaws suitable for maintenance of electronic devices.",
    ],
    modelNos: ["ER-125", "ER-150"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
		"Material | Carbon steel for machinery（S58C）",
		"Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
		"Body hardness [HRC] | 42～50",
		"Edge hardness [HRC] | 54～62",
		"Spring | Coil spring",
		"Handle cover | PVC dipping",
		"Surface treatment | Anti-rust oils",
    ],
    detail: MicroLongNosePliers,
    path: '/tools/miniTools/microLongNosePliers',
    id: 'microLongNosePliers'
};

export default microLongNosePliers;