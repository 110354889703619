import greaseNipplesInchSize140pcsImage from "../../../static/images/tools/greaseNipples/GE-215.jpg";
import GreaseNipplesMetricSize140pcs
    from "../../../components/ToolDetails/GreaseNipples/GreaseNipplesMetricSize140pcs/GreaseNipplesMetricSize140pcs";

const greaseNipplesInchSize140pcs = {
    name: '140pcs Grease Nipples (Inch Size)',
    image: greaseNipplesInchSize140pcsImage,
    images: [greaseNipplesInchSize140pcsImage],
    modelNos: ["GE-215"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: ["Kit contains 6 popular & useful inch sizes."],
    specifications: [],
    detail: GreaseNipplesMetricSize140pcs,
    path: '/tools/greaseNipples/greaseNipplesInchSize140pcs',
    id: 'greaseNipplesInchSize140pcs'
}

export default greaseNipplesInchSize140pcs;