import slipJointPliers from "./pliers/slipJointPliers";
import bentNosePlier from "./pliers/bentNosePlier";
import sideCuttingPlierEurope from "./pliers/sideCuttingPlierEurope";
import sideCuttingPlier from "./pliers/sideCuttingPlier";
import groovedWaterPumpPliers from "./pliers/groovedWaterPumpPlier";
import endCuttingPlier from "./pliers/endCuttingPliers";
import slantEdgeCuttingPlier from "./pliers/slantEdgeCuttingPliers";
import longNosePlier from "./pliers/longNosePlier";
import multiPurposeLongNosePlier from "./pliers/multiPurposeLongNosePlier";
import snapRingPliersStraightJawExternalRings from "./pliers/snapRingPliersStraightJawExternalRings";
import snapRingPliersBentJawExternalRings from "./pliers/snapRingPliersBentJawExternalRings";
import snapRingPliersStraightJawInternalRings from "./pliers/snapRingPliersStraightJawInternalRings";
import snapRingPliersBentJawInternalRings from "./pliers/snapRingPliersBentJawInternalRings";
import snapRingPliersForSmallInternalRings from "./pliers/snapRingPliersForSmallInternalRings";
import snapRingPliersForSmallExternalRings from "./pliers/snapRingPliersForSmallExternalRings";
import duckBillPliers from "./pliers/duckBillPliers";
import shearingPliers from "./pliers/shearingPliers";
import combinationPlier from "./pliers/combinationPlier";


const pliers = [
    slipJointPliers,
    bentNosePlier,
    sideCuttingPlierEurope,
    sideCuttingPlier,
    groovedWaterPumpPliers,
    endCuttingPlier,
    slantEdgeCuttingPlier,
    longNosePlier,
    multiPurposeLongNosePlier,
    snapRingPliersStraightJawExternalRings,
    snapRingPliersBentJawExternalRings,
    snapRingPliersStraightJawInternalRings,
    snapRingPliersBentJawInternalRings,
    snapRingPliersForSmallInternalRings,
    snapRingPliersForSmallExternalRings,
    duckBillPliers,
    shearingPliers,
    combinationPlier,
];

export default pliers;