import sideCuttingPlierImage from '../../../static/images/tools/pliers/cp150.jpg'
import sideCuttingPlierMeasurements from '../../../static/images/tools/pliers/cp150Measurements.jpg'
import SideCuttingPlier from "../../../components/ToolDetails/Pliers/SideCuttingPlier/SideCuttingPlier";

const sideCuttingPlier = {
    name: 'Side Cutting Pliers',
    image: sideCuttingPlierImage,
    images: [sideCuttingPlierImage, sideCuttingPlierMeasurements],
    modelNos: ["CP-150", "CP-175", "CP-200"],
    company: "King TTC",
    madeIn: 'Japan',
    specifications: [
        "Material | Carbon steel for machinery（S58C）",
        "Heat Treatment | Oxide-free heat treatment, Induction hardening on edge",
        "Body hardness [HRC] | 42～50",
        "Edge hardness [HRC] | 58～64",
        "Handle cover | PVC dipping",
        "Surface treatment | Anti-rust oils"
    ],
    detail: SideCuttingPlier,
    description: [
        "For electric works.",
        "For cutting wire, bending metal sheets and so on.",
    ],
    path: '/tools/pliers/sideCuttingPlier',
    id: 'sideCuttingPlier',
}

export default sideCuttingPlier;