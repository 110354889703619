import React from "react";
import {Table} from "react-bootstrap";

export default function AviationTinCutterWithSpring() {
    return <>
        <Table striped bordered hover variant="dark" responsive>
            <thead>
            <tr>
                <th>PRODUCT NO.</th>
                <th>Weight<br/>[g]</th>
            </tr>
            </thead>
            <tbody>
            <tr>
            </tr>
            <tr>
                <td>ATS10</td>
                <td>250</td>
            </tr>

            </tbody>
        </Table>
    </>
}