import batteryClamp150AmpImage from "../../../static/images/tools/batteryTerminalHolder/batteryClamp150Amp.jpg";

const batteryClamp150Amp = {
    name: 'Battery Clamp (150 Amp)',
    image: batteryClamp150AmpImage,
    images: [batteryClamp150AmpImage],
    modelNos: ["111-7152"],
    company: "AutoMaster",
    madeIn: 'Taiwan',
    description: [],
    specifications: [],
    detail: () => null,
    path: '/tools/batteryTerminalHolder/batteryClamp150Amp',
    id: 'batteryClamp150Amp'
};

export default batteryClamp150Amp;